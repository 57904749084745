import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "../../components/Grid";

export default function EmployeesList() {
  const { t } = useTranslation();
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData(id) {
      try {
        return [
          {
            key: "1",
            identifier: "E123456",
            name: "Empregado 1",
            responsibility: "Capataz",
            cpf: "462.147.340-94",
            createdAt: `${new Date()}`,
          },
          {
            key: "2",
            identifier: "E123457",
            name: "Empregado 2",
            responsibility: "Cozinheiro",
            cpf: "392.393.180-89",
            createdAt: `${new Date()}`,
          },
          {
            key: "3",
            identifier: "E123458",
            name: "Empregado 3",
            responsibility: "Pedreiro",
            cpf: "262.873.970-47",
            createdAt: `${new Date()}`,
          },
        ];
      } catch (error) {
        console.log("Erro ao buscar dados:", error);
      }
    }

    async function initial() {
      const data = await fetchData();
      setEmployees(data);
    }

    initial();
  }, [currentPage, pageSize, search, location.pathname]);

  const handleDelete = async (key) => {
    try {
      const updatedFarms = employees.filter((farm) => farm.key !== key);
      setEmployees(updatedFarms);
    } catch (error) {
      console.log("Erro ao excluir empregado:", error);
    }
  };
  const handleAdd = () => {
    navigate("/employees/create");
  };

  const handleEdit = (key) => {
    navigate(`/employees/${key}/edit`);
  };

  const handleView = (key) => {
    navigate(`/employees/${key}/view`);
  };

  const handleChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <div>
      <Grid
        data={employees}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
        onChangePage={handleChangePage}
        onSearch={handleSearch}
        nameButtom={t("Add_Collaborators")}
        showViewButton={true}
        showEditButton={true}
        showDeleteButton={true}
        showCreateButton={true}
        placeholderSearch={t("Search_employees")}
        columns={[
          {
            title: t("Identifier"),
            dataIndex: "identifier",
            key: "identifier",
          },
          {
            title: t("Name_collaborator"),
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: t("Responsibility"),
            dataIndex: "responsibility",
            key: "responsibility",
          },
          {
            title: "CPF",
            dataIndex: "cpf",
            key: "cpf",
          },
          {
            title: t("CreatedAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            render: (text, record) => (
              <span>{new Date(record.createdAt).toLocaleDateString()}</span>
            ),
          },
        ]}
      />
    </div>
  );
}
