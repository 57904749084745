import { LockOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Form, Input } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { AuthContext } from "../../../contexts/auth";
import { phoneMaskBR } from "../../../utils/mask";
import "../entitlement.css";

export default function Register() {
  const { register, loadingAuth } = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .required("O e-mail é obrigatório"),
    phone: Yup.string()
      .min(15, "Número de telefone inválido")
      .max(18, "Número de telefone inválido")
      .required("O telefone é obrigatório"),
    name: Yup.string().required("O nome é obrigatório"),
    password: Yup.string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
      .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
      .matches(/[0-9]/, "A senha deve conter pelo menos um número")
      .required("A senha é obrigatória"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "As senhas não coincidem")
      .required("Confirme sua senha"),
  });

  async function registerUser(values) {
    await register(
      values.email,
      values.password,
      values.confirm_password,
      values.name,
      values.phone,
      values.whatsapp
    );
  }

  return (
    <div className="login-container">
      <Card title="Cadastrar" className="login-card">
        <Formik
          initialValues={{
            email: "",
            phone: "",
            name: "",
            password: "",
            confirm_password: "",
            isWhatsapp: false,
          }}
          validationSchema={validationSchema}
          onSubmit={registerUser}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
          }) => (
            <Form layout="vertical" onSubmitCapture={handleSubmit}>
              <Form.Item
                help={touched.name && errors.name ? errors.name : ""}
                validateStatus={touched.name && errors.name ? "error" : ""}
              >
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Nome"
                />
              </Form.Item>
              <Form.Item
                help={touched.email && errors.email ? errors.email : ""}
                validateStatus={touched.email && errors.email ? "error" : ""}
              >
                <Input
                  prefix={<MailOutlined />}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="E-mail"
                />
              </Form.Item>
              <Form.Item
                help={touched.phone && errors.phone ? errors.phone : ""}
                validateStatus={touched.phone && errors.phone ? "error" : ""}
              >
                <Input
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Telefone"
                  onKeyUp={(event) => phoneMaskBR(event)}
                  prefix={<PhoneOutlined />}
                />
              </Form.Item>
              <Form.Item name="isWhatsapp" valuePropName="checked">
                <Checkbox>É WhatsApp?</Checkbox>
              </Form.Item>
              <Form.Item
                help={
                  touched.password && errors.password ? errors.password : ""
                }
                validateStatus={
                  touched.password && errors.password ? "error" : ""
                }
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Senha"
                />
              </Form.Item>
              <Form.Item
                help={
                  touched.confirm_password && errors.confirm_password
                    ? errors.confirm_password
                    : ""
                }
                validateStatus={
                  touched.confirm_password && errors.confirm_password
                    ? "error"
                    : ""
                }
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  name="confirm_password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Confirmar senha"
                />
              </Form.Item>
              <Form.Item>
                <div className="links-container">
                  Já tem uma conta? <Link to="/login">Faça login</Link>
                </div>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {!loadingAuth ? "Cadastrar" : "Enviando ..."}
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}
