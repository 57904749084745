// components/AddressesFieldArray.js
import {
  DeleteOutlined,
  EnvironmentOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
} from "antd";
import { FieldArray } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Map } from "../pages/Maps/Map";

export const AddressForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  onlyOne,
  allowDelete,
  allowLatLong,
  allowPubliclyAccessible = true,
  setFieldValue,
  isView = false,
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: values.adresses.length > 0 ? values.adresses[0].latitude : null,
    longitude: values.adresses.length > 0 ? values.adresses[0].longitude : null,
  });

  function handleMapClick(lat, long) {
    setSelectedLocation({ latitude: lat, longitude: long });
  }

  const handleMapModalButtonClick = () => {
    setMapModalVisible(false);
  };

  const getTypes = () => {
    return ["Residencial", "Comercial"];
  };
  const getCities = (state) => {
    switch (state) {
      case "SP":
        return ["São Paulo", "Campinas", "Guarulhos"];
      case "RJ":
        return ["Rio de Janeiro", "Niterói", "Campos"];
      case "MG":
        return ["Belo Horizonte", "Uberlândia", "Juiz de Fora"];
      default:
        return [];
    }
  };

  const getStates = () => {
    return ["SP", "MG", "RJ"];
  };
  return (
    <FieldArray name="adresses">
      {({ remove, push }) => (
        <>
          {values.adresses?.map((address, index) => (
            <Row gutter={16} key={index}>
              <Col xs={24} md={8}>
                <Form.Item
                  help={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].street &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].street
                      ? errors.adresses[index].street
                      : ""
                  }
                  validateStatus={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].street &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].street
                      ? "error"
                      : ""
                  }
                >
                  <Input
                    name={`adresses.${index}.street`}
                    value={address.street}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Street")}
                    disabled={isView}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  help={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].number &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].number
                      ? errors.adresses[index].number
                      : ""
                  }
                  validateStatus={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].number &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].number
                      ? "error"
                      : ""
                  }
                >
                  <Input
                    type="number"
                    name={`adresses.${index}.number`}
                    value={address.number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Number")}
                    disabled={isView}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  help={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].neighborhood &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].neighborhood
                      ? errors.adresses[index].neighborhood
                      : ""
                  }
                  validateStatus={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].neighborhood &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].neighborhood
                      ? "error"
                      : ""
                  }
                >
                  <Input
                    name={`adresses.${index}.neighborhood`}
                    value={address.neighborhood}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Neighborhood")}
                    disabled={isView}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  help={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].complement &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].complement
                      ? errors.adresses[index].complement
                      : ""
                  }
                  validateStatus={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].complement &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].complement
                      ? "error"
                      : ""
                  }
                >
                  <Input
                    name={`adresses.${index}.complement`}
                    value={address.complement}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Complement")}
                    disabled={isView}
                  />
                </Form.Item>
              </Col>

              {allowLatLong && (
                <>
                  <Col xs={24} md={8}>
                    <Form.Item
                      help={
                        touched.adresses &&
                        touched.adresses[index] &&
                        touched.adresses[index].latitude &&
                        errors.adresses &&
                        errors.adresses[index] &&
                        errors.adresses[index].latitude
                          ? errors.adresses[index].latitude
                          : ""
                      }
                      validateStatus={
                        touched.adresses &&
                        touched.adresses[index] &&
                        touched.adresses[index].latitude &&
                        errors.adresses &&
                        errors.adresses[index] &&
                        errors.adresses[index].latitude
                          ? "error"
                          : ""
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <EnvironmentOutlined
                          onClick={() => setMapModalVisible(true)}
                          style={{
                            color: "green",
                            opacity: 1,
                            cursor: "pointer",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        />
                        <Input
                          name={`adresses.${index}.latitude`}
                          value={(address.latitude = selectedLocation.latitude)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Latitude")}
                          disabled={true}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      help={
                        touched.adresses &&
                        touched.adresses[index] &&
                        touched.adresses[index].longitude &&
                        errors.adresses &&
                        errors.adresses[index] &&
                        errors.adresses[index].longitude
                          ? errors.adresses[index].longitude
                          : ""
                      }
                      validateStatus={
                        touched.adresses &&
                        touched.adresses[index] &&
                        touched.adresses[index].longitude &&
                        errors.adresses &&
                        errors.adresses[index] &&
                        errors.adresses[index].longitude
                          ? "error"
                          : ""
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <EnvironmentOutlined
                          onClick={() => setMapModalVisible(true)}
                          style={{
                            color: "green",
                            opacity: 1,
                            cursor: "pointer",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        />
                        <Input
                          name={`adresses.${index}.longitude`}
                          value={
                            (address.longitude = selectedLocation.longitude)
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("Longitude")}
                          disabled={true}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col xs={24} md={8}>
                <Form.Item
                  help={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].zipCode &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].zipCode
                      ? errors.adresses[index].zipCode
                      : ""
                  }
                  validateStatus={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].zipCode &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].zipCode
                      ? "error"
                      : ""
                  }
                >
                  <Input
                    name={`adresses.${index}.zipCode`}
                    value={address.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Zipcode")}
                    disabled={isView}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  help={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].state &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].state
                      ? errors.adresses[index].state
                      : ""
                  }
                  validateStatus={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].state &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].state
                      ? "error"
                      : ""
                  }
                >
                  <Select
                    name={`adresses.${index}.state`}
                    value={address.state || undefined}
                    onChange={(value) =>
                      handleChange({
                        target: { name: `adresses.${index}.state`, value },
                      })
                    }
                    onBlur={handleBlur}
                    placeholder={t("State")}
                    disabled={isView}
                  >
                    {getStates().map((state) => (
                      <Option value={state} key={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  help={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].city &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].city
                      ? errors.adresses[index].city
                      : ""
                  }
                  validateStatus={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].city &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].city
                      ? "error"
                      : ""
                  }
                >
                  <Select
                    name={`adresses.${index}.city`}
                    value={address.city || undefined}
                    onChange={(value) =>
                      handleChange({
                        target: { name: `adresses.${index}.city`, value },
                      })
                    }
                    onBlur={handleBlur}
                    placeholder={t("City")}
                    disabled={!address.state || isView} // Desabilita até que o estado tenha um valor
                  >
                    {getCities(address.state).map((city) => (
                      <Option value={city} key={city}>
                        {city}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  help={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].type &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].type
                      ? errors.adresses[index].type
                      : ""
                  }
                  validateStatus={
                    touched.adresses &&
                    touched.adresses[index] &&
                    touched.adresses[index].type &&
                    errors.adresses &&
                    errors.adresses[index] &&
                    errors.adresses[index].type
                      ? "error"
                      : ""
                  }
                >
                  <Select
                    name={`adresses.${index}.type`}
                    value={address.type || undefined}
                    disabled={isView}
                    onChange={(value) =>
                      handleChange({
                        target: { name: `adresses.${index}.type`, value },
                      })
                    }
                    onBlur={handleBlur}
                    placeholder={t("Type_of_address")}
                  >
                    {getTypes().map((type) => (
                      <Option value={type} key={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {mapModalVisible && (
                  <>
                    <Row>
                      <Col>
                        <div style={{ height: 400, width: 600 }}>
                          <Map
                            handleMapClick={handleMapClick}
                            hidePreviousMarkers={true}
                            handleCloseButton={handleMapModalButtonClick}
                            hideButton={true}
                            latitude={address.latitude}
                            longitude={address.longitude}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>

              {allowPubliclyAccessible && (
                <Col xs={24} md={8}>
                  <Form.Item
                    label={t("Publicly_accessible_label")}
                    help={
                      touched.adresses &&
                      touched.adresses[index] &&
                      touched.adresses[index].publicly_accessible &&
                      errors.adresses &&
                      errors.adresses[index] &&
                      errors.adresses[index].publicly_accessible
                        ? errors.adresses[index].publicly_accessible
                        : ""
                    }
                    validateStatus={
                      touched.adresses &&
                      touched.adresses[index] &&
                      touched.adresses[index].publicly_accessible &&
                      errors.adresses &&
                      errors.adresses[index] &&
                      errors.adresses[index].publicly_accessible
                        ? "error"
                        : ""
                    }
                  >
                    <Tooltip title={t("Publicly_accessible")}>
                      <Checkbox
                        name={`adresses.${index}.publicly_accessible`}
                        checked={address.publicly_accessible}
                        onChange={(e) =>
                          setFieldValue(
                            `adresses[${index}].publicly_accessible`,
                            e.target.checked
                          )
                        }
                        onBlur={handleBlur}
                        disabled={isView}
                      />
                    </Tooltip>
                  </Form.Item>
                </Col>
              )}

              {allowDelete && (
                <Col xs={24} md={8}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => remove(index)}
                      danger
                      icon={<DeleteOutlined />}
                    >
                      {t("Delete_address")}
                    </Button>
                  </Form.Item>
                </Col>
              )}
              <Divider orientation="right">
                {t("Address")} {index + 1}
              </Divider>
            </Row>
          ))}
          {!isView && !onlyOne && (
            <Button
              type="default"
              onClick={() => {
                if (allowLatLong) {
                  push({
                    street: "",
                    neighborhood: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    number: "",
                    complement: "",
                    latitude: "",
                    longitude: "",
                    publicly_accessible: false,
                    type: "Residencial",
                  });
                } else {
                  push({
                    street: "",
                    neighborhood: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    number: "",
                    complement: "",
                    publicly_accessible: false,
                    type: "Residencial",
                  });
                }
              }}
              icon={<PlusCircleOutlined />}
            >
              {t("New_address")}
            </Button>
          )}
        </>
      )}
    </FieldArray>
  );
};
