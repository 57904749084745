import { cnpj as cnpjValidator } from "cpf-cnpj-validator";
import * as Yup from "yup";

export const getValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("O nome é obrigatório"),
    publicly_accessible: Yup.boolean().optional().default(false),
    segment: Yup.string().required("O segmento é obrigatório"),
    cnpj: Yup.string()
      .required("O CNPJ é obrigatório")
      .test("nomeDaValidacao", "CNPJ inválido", (value) => {
        if (value) {
          if (cnpjValidator.isValid(value)) {
            return true;
          }
          return false;
        }
        return true;
      }),
    adresses: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().optional().default(""),
          street: Yup.string().required("A rua é obrigatória"),
          neighborhood: Yup.string().required("O bairro é obrigatório"),
          city: Yup.string().required("A cidade é obrigatória"),
          state: Yup.string().required("O estado é obrigatório"),
          zipCode: Yup.string().optional().default(""),
          number: Yup.string().required("O número é obrigatório"),
          complement: Yup.string().optional().default(""),
          type: Yup.string().required("O tipo de endereço é obrigatório"),
          publicly_accessible: Yup.boolean().optional().default(false),
        })
      )
      .optional(),
    products: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("O nome é obrigatório"),
          description: Yup.string().optional(),
          category: Yup.string().optional(),
          price: Yup.string().optional(),
          unit: Yup.string().optional().optional(),
          supplier: Yup.string().optional(),
          validity: Yup.date().optional(),
          weight_volume: Yup.string().optional(),
          obs: Yup.string().optional(),
          identifier: Yup.string().required("O Identificador é obrigatório"),
        })
      )
      .optional(),
    phones: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().optional().default(""),
          phone: Yup.string().required("O telefone é obrigatório"),
          type: Yup.string().required("O tipo de telefone é obrigatório"),
          publicly_accessible: Yup.boolean().optional().default(false),
        })
      )
      .optional(),
    emails: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().optional().default(""),
          email: Yup.string().required("O email é obrigatório"),
          type: Yup.string().required("O tipo de email é obrigatório"),
          publicly_accessible: Yup.boolean().optional().default(false),
        })
      )
      .optional(),
  });
};
