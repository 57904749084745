import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  /* body {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
  }

  .ant-layout-header {
    background-color: ${({ theme }) =>
      theme.HEADER_BACKGROUND_COLOR} !important;
  }

  .ant-layout-sider {
    background-color: ${({ theme }) => theme.SIDER_BACKGROUND_COLOR} !important;
  }

  .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.MENU_SELECTED_COLOR} !important;
  }

  .ant-menu-item {
    color: ${({ theme }) => theme.menuTextColor} !important;
  } */
`;

export default GlobalStyle;
