import {
  CompressOutlined,
  EditOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  ExpandOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
} from "antd";
import { cpf as cpfValidator } from "cpf-cnpj-validator";
import { Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { AddressForm } from "../../components/AddressForm";
import { EmailsForm } from "../../components/EmailsForm";
import HeaderInfosBase from "../../components/HeaderInfosBase";
import { PhonesForm } from "../../components/PhonesForm";
import { AuthContext } from "../../contexts/auth";
import { formatCpf, phoneMaskBR, phoneMaskBRDefault } from "../../utils/mask";
import {
  editFarmer,
  editPerfil,
  getBehaviors,
  getNeeds,
  getUser,
} from "./service/farmer.service";

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

export default function Perfil() {
  const { t } = useTranslation();
  const { user, updateUser } = useContext(AuthContext);
  const [activeKey, setActiveKey] = useState([]);
  const [optionsBehaviors, setBevaviors] = useState([]);
  const [optionsNeeds, setNeeds] = useState([]);
  const [name, setName] = useState("");
  const [descriptionBehavior, setDescriptionBehavior] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [infosHeader, setInfosHeader] = useState([
    {
      label: t("Name"),
      value: user.name,
      type: "label",
    },
    {
      label: t("Email"),
      value: user.email,
      type: "label",
    },
    {
      label: t("Phone"),
      value: user.phone ? phoneMaskBRDefault(user.phone) : "",
      type: "label",
    },
    {
      label: "CPF",
      value: "",
      type: "label",
    },
    {
      label: t("User_Profile"),
      value: "",
      type: "label",
    },
    {
      label: t("Publicly_accessible_label"),
      value: "Não",
      type: "tag",
    },
  ]);

  const [initialValues, setInitialValues] = useState({
    name: user.name,
    email: user.email,
    phone: user.phone ? phoneMaskBRDefault(user.phone) : "",
    whatsapp: user.whatsapp,
    cpf: "",
    nickname: "",
    adresses: [],
    publicly_accessible: false,
    phones: [],
    emails: [],
    needs: [],
    behaviors: [],
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("NameIsRequired")),
    nickname: Yup.string().optional(),
    email: Yup.string().email(t("InvalidEmail")).required(t("EmailIsRequired")),
    publicly_accessible: Yup.boolean().optional(),
    phone: Yup.string().optional(),
    needs: Yup.array().optional(),
    behaviors: Yup.array().optional(),
    cpf: Yup.string().test("nomeDaValidacao", t("CPFInvalid"), (value) => {
      if (value) {
        if (cpfValidator.isValid(value)) {
          return true;
        }
        return false;
      }
      return true;
    }),
    whatsapp: Yup.boolean().optional(),
    adresses: Yup.array()
      .of(
        Yup.object().shape({
          street: Yup.string().required(t("StreetIsRequired")),
          neighborhood: Yup.string().required(t("NeighborhoodIsRequired")),
          city: Yup.string().required(t("CityIsRequired")),
          state: Yup.string().required(t("StateIsRequired")),
          zipCode: Yup.string().optional().default(""),
          number: Yup.string().required(t("NumberIsRequired")),
          complement: Yup.string().optional().default(""),
          type: Yup.string().required(t("AddressTypeIsRequired")),
          publicly_accessible: Yup.boolean().optional().default(false),
        })
      )
      .optional(),
    phones: Yup.array()
      .of(
        Yup.object().shape({
          phone: Yup.string().required(t("PhoneIsRequired")),
          type: Yup.string().required(t("PhoneTypeIsRequired")),
          publicly_accessible: Yup.boolean().optional().default(false),
        })
      )
      .optional(),
    emails: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string().required(t("EmailIsRequired")),
          type: Yup.string().required(t("EmailTypeIsRequired")),
          publicly_accessible: Yup.boolean().optional().default(false),
        })
      )
      .optional(),
  });

  const findUser = useCallback(async () => {
    const data = await getUser(user.user_id);
    if (data) setInitialValues(data);
    if (data) {
      setInfosHeader([
        {
          label: t("Name"),
          value: data.name,
          type: "label",
        },
        {
          label: t("Email"),
          value: data.email,
          type: "label",
        },
        {
          label: t("Phone"),
          value: data.phone ? phoneMaskBRDefault(data.phone) : "",
          type: "label",
        },
        {
          label: "CPF",
          value: data.cpf,
          type: "label",
        },
        {
          label: t("User_Profile"),
          value: data.behaviors.length > 0 ? data.behaviors.join(" | ") : "",
          type: "label",
        },
        {
          label: t("Publicly_accessible_label"),
          value: data.publicly_accessible ? t("Yes") : t("No"),
          type: "tag",
        },
      ]);
    }
  }, [user.user_id, t]);

  const findBehaviors = useCallback(async () => {
    const data = await getBehaviors();
    const options = [];
    data.map((b) =>
      options.push({
        label: b.behavior_description,
        value: b.behavior,
      })
    );
    setBevaviors(options);
  }, []);

  const findNeeds = useCallback(async () => {
    const data = await getNeeds();
    setNeeds(data.map((n) => n.need));
  }, []);

  useEffect(() => {
    findUser();
    findBehaviors();
    findNeeds();
  }, [findUser, findBehaviors, findNeeds]);

  async function editPerfilService(values) {
    try {
      await editPerfil(
        values.name,
        values.email,
        values.phone,
        values.whatsapp
      );
      const payload = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        whatsapp: values.whatsapp,
        cpf: values.cpf,
        nickname: values.nickname,
        emails: values.emails,
        phones: values.phones,
        adresses: values.adresses,
        publicly_accessible: values.publicly_accessible,
        behaviors: values.behaviors,
        needs: values.needs,
      };
      await editFarmer(payload, user.user_id);

      updateUser(
        values.name,
        values.email,
        values.phone,
        values.whatsapp,
        user.user_id
      );
      await findUser();
    } catch (error) {
      console.log("AQUI", error);
    }
  }

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  function openPanels() {
    setActiveKey([1, 2, 3]);
  }

  function togglePanels() {
    if (activeKey.length === 3) {
      setActiveKey([]);
    } else {
      setActiveKey([1, 2, 3]);
    }
  }

  const genExtra = (key) => {
    switch (key) {
      case 1:
        return (
          <EnvironmentOutlined
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        );
      case 2:
        return (
          <PhoneOutlined
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        );
      case 3:
        return (
          <MailOutlined
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={editPerfilService}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form onSubmitCapture={handleSubmit}>
              <div style={{ position: "relative" }}>
                <HeaderInfosBase
                  linkRedirect={"/dashboard"}
                  title={"Dados do Usuário"}
                  data={infosHeader}
                />
              </div>

              <Row
                gutter={16}
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col xs={24} md={8} style={{ marginBottom: "5px" }}>
                  <Form.Item
                    help={touched.name && errors.name ? errors.name : ""}
                    validateStatus={touched.name && errors.name ? "error" : ""}
                  >
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("Name")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} style={{ marginBottom: "5px" }}>
                  <Form.Item
                    help={
                      touched.nickname && errors.nickname ? errors.nickname : ""
                    }
                    validateStatus={
                      touched.nickname && errors.nickname ? "error" : ""
                    }
                  >
                    <Input
                      name="nickname"
                      value={values.nickname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("Nikname")}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={8} style={{ marginBottom: "5px" }}>
                  <Form.Item
                    help={touched.cpf && errors.cpf ? errors.cpf : ""}
                    validateStatus={touched.cpf && errors.cpf ? "error" : ""}
                  >
                    <Input
                      name="cpf"
                      value={values.cpf}
                      onChange={(event) => {
                        const formattedValue = formatCpf(event);
                        setFieldValue("cpf", formattedValue);
                      }}
                      onBlur={handleBlur}
                      onKeyUp={(event) => formatCpf(event)}
                      placeholder="CPF"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: "10px" }}>
                <Col xs={24} md={12}>
                  <Form.Item
                    help={touched.email && errors.email ? errors.email : ""}
                    validateStatus={
                      touched.email && errors.email ? "error" : ""
                    }
                  >
                    <Input
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("Email")}
                      prefix={<MailOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    help={touched.phone && errors.phone ? errors.phone : ""}
                    validateStatus={
                      touched.phone && errors.phone ? "error" : ""
                    }
                  >
                    <Input
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("Phone")}
                      onKeyUp={(event) => phoneMaskBR(event)}
                      prefix={<PhoneOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={4}>
                  <Form.Item
                    label="Whatsapp"
                    help={
                      touched.whatsapp && errors.whatsapp ? errors.whatsapp : ""
                    }
                    validateStatus={
                      touched.whatsapp && errors.whatsapp ? "error" : ""
                    }
                  >
                    <Checkbox
                      name="whatsapp"
                      checked={values.whatsapp}
                      onChange={(e) =>
                        setFieldValue("whatsapp", e.target.checked)
                      }
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginBottom: "10px" }}>
                <Col xs={24} md={4}>
                  <Form.Item
                    label={t("Publicly_accessible_label")}
                    help={
                      touched.publicly_accessible && errors.publicly_accessible
                        ? errors.publicly_accessible
                        : ""
                    }
                    validateStatus={
                      touched.publicly_accessible && errors.publicly_accessible
                        ? "error"
                        : ""
                    }
                  >
                    <Tooltip title={t("Publicly_accessible")}>
                      <Checkbox
                        name="publicly_accessible"
                        checked={values.publicly_accessible}
                        onChange={(e) =>
                          setFieldValue("publicly_accessible", e.target.checked)
                        }
                        onBlur={handleBlur}
                      />
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>

              <Tabs defaultActiveKey="1">
                <TabPane tab={t("Info_basics")} key="1">
                  <Row justify="end" style={{ marginBottom: "15px" }}>
                    <Col>
                      <Button
                        type="dashed"
                        onClick={togglePanels}
                        icon={
                          activeKey.length === 3 ? (
                            <CompressOutlined />
                          ) : (
                            <ExpandOutlined />
                          )
                        }
                      >
                        {activeKey.length === 3 ? t("Colapse") : t("Expand")}
                      </Button>
                    </Col>
                  </Row>
                  <Collapse activeKey={activeKey} onChange={onChange}>
                    <Panel header={t("Adresses")} key="1" extra={genExtra(1)}>
                      <AddressForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        onlyOne={false}
                        allowDelete={true}
                        allowLatLong={false}
                        setFieldValue={setFieldValue}
                      />
                    </Panel>
                    <Panel header={t("Phones")} key="2" extra={genExtra(2)}>
                      <PhonesForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </Panel>
                    <Panel header={t("Emails")} key="3" extra={genExtra(3)}>
                      <EmailsForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </Panel>
                  </Collapse>
                </TabPane>
                <TabPane tab={t("Additional_Information")} key="2">
                  <Row
                    gutter={16}
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Col xs={24} md={8}>
                      <Form.Item
                        help={
                          errors.behaviors && errors.behaviors
                            ? errors.behaviors
                            : ""
                        }
                        validateStatus={
                          touched.behaviors && errors.behaviors ? "error" : ""
                        }
                      >
                        <Select
                          allowClear
                          mode="multiple"
                          value={values.behaviors || []}
                          onChange={(selectedValues) => {
                            handleChange({
                              target: {
                                name: "behaviors",
                                value: selectedValues,
                              },
                            });
                          }}
                          onBlur={handleBlur}
                          placeholder={t("My_Perfil")}
                          showSearch
                        >
                          {optionsBehaviors.map((b) => (
                            <Option value={b.value} key={b.value}>
                              {b.value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex="none">
                      <ExclamationCircleOutlined
                        onClick={() => setOpenModal(true)}
                      />
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Col xs={24} md={15}>
                      <Form.Item
                        help={touched.needs && errors.needs ? errors.needs : ""}
                        validateStatus={
                          touched.needs && errors.needs ? "error" : ""
                        }
                      >
                        <Select
                          allowClear
                          mode="multiple"
                          value={values.needs || []}
                          onChange={(selectedValues) => {
                            handleChange({
                              target: {
                                name: "needs",
                                value: selectedValues,
                              },
                            });
                          }}
                          placeholder={t("Needs")}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider />
                              <Space>
                                <TextArea
                                  style={{ width: "400px" }}
                                  placeholder={t("Enter_your_need")}
                                  value={name}
                                  onChange={onNameChange}
                                />

                                {name && (
                                  <Button
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                      const updatedNeeds = [
                                        ...values.needs,
                                        name,
                                      ];
                                      handleChange({
                                        target: {
                                          name: "needs",
                                          value: updatedNeeds,
                                        },
                                      });
                                    }}
                                  >
                                    {t("New_item")}
                                  </Button>
                                )}
                              </Space>
                            </>
                          )}
                          options={optionsNeeds.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
              <div style={{ marginTop: "32px" }}>
                <Button
                  onClick={() => openPanels()}
                  type="primary"
                  htmlType="submit"
                  icon={<EditOutlined />}
                >
                  {t("Change")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <Modal
        onOk={() => setOpenModal(false)}
        title="Infos"
        open={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={t("Behaviors")}
          onChange={(value) => {
            setDescriptionBehavior(value);
          }}
          showSearch
        >
          {optionsBehaviors.map((b) => (
            <Option value={b.label} key={b.value}>
              {b.value}
            </Option>
          ))}
        </Select>
        <div style={{ marginTop: "10px" }}>
          <p>{descriptionBehavior}</p>
        </div>
      </Modal>
    </div>
  );
}
