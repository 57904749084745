import axios from "../../../utils/axiosInterceptorEntitlement";
import axiosPlataform from "../../../utils/axiosInterceptorPlataform";

export const changePasswordService = async (password, confirmePassword) => {
  const user = JSON.parse(localStorage.getItem("userLogged"));
  const payload = {
    password: password,
    confirm_password: confirmePassword,
  };
  await axios.patch(`v1/user/${user.user_id}/change-password`, payload);
};

export const editPerfil = async (name, email, phone, whatsapp) => {
  const user = JSON.parse(localStorage.getItem("userLogged"));
  const payload = {
    email: email,
    name: name,
    phone: phone,
    whatsapp: whatsapp,
  };
  await axios.put(`v1/user/${user.user_id}`, payload);
};

export const editFarmer = async (payload, userId) => {
  return await axiosPlataform.put(`v1/farmer/${userId}`, payload);
};

export const getUser = async (userId) => {
  const result = await axiosPlataform.get(`v1/farmer/${userId}`);
  return result.data.data;
};

export const getBehaviors = async () => {
  const result = await axiosPlataform.get(`v1/behaviors`);
  return result.data.data;
};

export const getNeeds = async () => {
  const result = await axiosPlataform.get(`v1/needs`);
  return result.data.data;
};
