import {
  BellOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  HomeOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PictureOutlined,
  TeamOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  YuqueOutlined,
} from "@ant-design/icons";
import { Avatar, Breadcrumb, Card, Dropdown, Layout, Menu } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import brazilFlag from "../../assets/br.svg";
import spainFlag from "../../assets/es.svg";
import franceFlag from "../../assets/fr.svg";
import usaFlag from "../../assets/us.svg";
import { useLanguage } from "../../contexts/LanguageContext";
import { AuthContext } from "../../contexts/auth";

const { Header, Content, Footer, Sider } = Layout;

function Base({ children, breadcrumb, title }) {
  const { language, changeLanguage } = useLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, user } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);

  function LanguageSelector() {
    const languageMenu = (
      <Menu onClick={(e) => changeLanguage(e.key)}>
        <Menu.Item key="pt">
          <img
            src={brazilFlag}
            alt="Bandeira do Brasil"
            width="20"
            height="15"
            style={{ marginRight: "8px" }}
          />
          {t("Portuguese")}
        </Menu.Item>
        <Menu.Item key="es">
          <img
            src={spainFlag}
            alt="Bandeira da Espanha"
            width="20"
            height="15"
            style={{ marginRight: "8px" }}
          />
          {t("Spanish")}
        </Menu.Item>
        <Menu.Item key="fr">
          <img
            src={franceFlag}
            alt="Bandeira da França"
            width="20"
            height="15"
            style={{ marginRight: "8px" }}
          />
          {t("French")}
        </Menu.Item>
        <Menu.Item key="en-us">
          <img
            src={usaFlag}
            alt="Bandeira dos Estados Unidos"
            width="20"
            height="15"
            style={{ marginRight: "8px" }}
          />
          {t("English")}
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={languageMenu} placement="bottomRight">
        <Avatar
          shape="square"
          src={
            language === "pt"
              ? brazilFlag
              : language === "es"
              ? spainFlag
              : language === "fr"
              ? franceFlag
              : usaFlag
          }
          alt={
            language === "pt"
              ? t("Portuguese")
              : language === "es"
              ? t("Spanish")
              : language === "fr"
              ? t("French")
              : t("English")
          }
          style={{ cursor: "pointer" }}
        />
      </Dropdown>
    );
  }

  const getSelectedKey = () => {
    if (location.pathname.includes("/farms")) return "3";
    if (location.pathname.includes("/farms/publics")) return "3-2";
    if (location.pathname.includes("/dashboard")) return "1";
    if (location.pathname.includes("/maps")) return "2";
    if (location.pathname.includes("/suppliers")) return "5";
    if (location.pathname.includes("/employees")) return "6";
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        icon={<UserOutlined />}
        onClick={() => navigate(`/user/${user.user_id}/perfil`)}
      >
        {t("MyPerfil")}
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<LockOutlined />}
        onClick={() => navigate(`/user/${user.user_id}/perfil/change_password`)}
      >
        {t("ChangePassword")}
      </Menu.Item>
      <Menu.Item key="3" icon={<LogoutOutlined />} onClick={() => logout()}>
        {t("Exit")}
      </Menu.Item>
    </Menu>
  );

  const avatarMenu = (
    <Dropdown overlay={menu} placement="bottomRight">
      <Avatar icon={<UserOutlined />} />
    </Dropdown>
  );

  function getItem(label, key, icon, onClick, children) {
    return {
      key,
      icon,
      children,
      onClick,
      label,
    };
  }

  const items = [
    getItem(t("Dashboard"), "1", <DashboardOutlined />, () =>
      navigate("/dashboard")
    ),
    getItem(t("Map"), "2", <GlobalOutlined />, () => navigate("/maps")),
    getItem(t("Property"), "3", <PictureOutlined />, null, [
      getItem(t("MyProperties"), "3-1", <HomeOutlined />, () =>
        navigate("/farms")
      ),
      getItem(t("PublicProperties"), "3-2", <EnvironmentOutlined />, () =>
        navigate("/farms/publics")
      ),
    ]),
    getItem(t("Suppliers"), "5", <TeamOutlined />, null, [
      getItem(t("MySuppliers"), "5-1", <UserOutlined />, () =>
        navigate("/suppliers")
      ),
      getItem(t("PublicSuppliers"), "5-2", <EnvironmentOutlined />, () =>
        navigate("/suppliers/publics")
      ),
    ]),
    getItem(t("Employees"), "6", <UsergroupAddOutlined />, () =>
      navigate("/employees")
    ),
    getItem(t("Animals"), "7", <YuqueOutlined />, () => {}),
    getItem(t("Exit"), "4", <LogoutOutlined />, logout),
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        style={{ padding: 0, backgroundColor: "white" }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={null}
        collapsedWidth={80}
        width={240}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 64,
            margin: "0 16px",
            background: "rgba(255, 255, 255, 0.2)",
          }}
        >
          {/* Adicione o botão customizado de colapso aqui */}
          <button
            onClick={() => setCollapsed(!collapsed)}
            style={{
              background: "white",
              border: "none",
              outline: "none",
              color: "black",
              fontSize: "18px",
              cursor: "pointer",
              padding: "0 16px",
              height: "32px",
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </button>
        </div>
        <Menu
          selectedKeys={[getSelectedKey()]}
          mode="inline"
          items={items}
          onClick={(e) => {
            if (e.key === "2") {
              items[1].onClick();
            } else if (e.key === "4") {
              items[3].onClick();
            }
          }}
        />
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 64,
              backgroundColor: "white",
              padding: "0 24px",
            }}
          >
            <h1 style={{ margin: 0 }}>{title}</h1>
            <div style={{ display: "flex", alignItems: "center" }}>
              <BellOutlined style={{ fontSize: "24px", marginRight: "16px" }} />
              <LanguageSelector />
              <div style={{ marginLeft: "8px" }}>{avatarMenu}</div>
              <div style={{ marginLeft: "8px" }}>{user.name}</div>
            </div>
          </div>
        </Header>
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>{breadcrumb}</Breadcrumb.Item>
          </Breadcrumb>
          <Card
            style={{
              height: "calc(100% - 32px)",
              width: "100%",
            }}
            bodyStyle={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {children}
          </Card>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          BovControl ©2023 Created by BackEnd Development
        </Footer>
      </Layout>
    </Layout>
  );
}

export default Base;
