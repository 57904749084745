import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { AuthContext } from "../../../contexts/auth";
import "../entitlement.css";

export default function ChangePassword() {
  const { changePassword, loadingAuth } = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .required("O e-mail é obrigatório"),
    password: Yup.string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
      .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
      .matches(/[0-9]/, "A senha deve conter pelo menos um número")
      .required("A senha é obrigatória"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "As senhas não coincidem")
      .required("Confirme sua senha"),
    code: Yup.string().required("O código é obrigatório"),
  });

  async function changePasswordUser(values) {
    await changePassword(
      values.email,
      values.password,
      values.confirm_password,
      values.code
    );
  }

  return (
    <div className="login-container">
      <Card title="Troca de Senha" className="login-card">
        <Formik
          initialValues={{
            email: "",
            password: "",
            confirm_password: "",
            code: "",
          }}
          validationSchema={validationSchema}
          onSubmit={changePasswordUser}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
          }) => (
            <Form layout="vertical" onSubmitCapture={handleSubmit}>
              <Form.Item
                help={touched.email && errors.email ? errors.email : ""}
                validateStatus={touched.email && errors.email ? "error" : ""}
              >
                <Input
                  prefix={<UserOutlined />}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="E-mail"
                />
              </Form.Item>
              <Form.Item
                help={touched.code && errors.code ? errors.code : ""}
                validateStatus={touched.code && errors.code ? "error" : ""}
              >
                <Input
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Código"
                />
              </Form.Item>
              <Form.Item
                help={
                  touched.password && errors.password ? errors.password : ""
                }
                validateStatus={
                  touched.password && errors.password ? "error" : ""
                }
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Senha"
                />
              </Form.Item>
              <Form.Item
                help={
                  touched.confirm_password && errors.confirm_password
                    ? errors.confirm_password
                    : ""
                }
                validateStatus={
                  touched.confirm_password && errors.confirm_password
                    ? "error"
                    : ""
                }
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  name="confirm_password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Confirmar senha"
                />
              </Form.Item>
              <Form.Item>
                <div className="links-container">
                  Já tem uma conta? <Link to="/login">Faça login</Link>
                </div>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {!loadingAuth ? "Enviar" : "Enviando ..."}
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}
