// components/AddressesFieldArray.js
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
} from "antd";
import { FieldArray } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { phoneMaskBR } from "../utils/mask";

export const PhonesForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  allowPubliclyAccessible = true,
  isView = false,
}) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const getTypes = () => {
    return ["Residencial", "Comercial"];
  };
  return (
    <FieldArray name="phones">
      {({ remove, push }) => (
        <>
          {values.phones?.map((phones, index) => (
            <Row gutter={16} key={index}>
              <Col xs={24} md={8}>
                <Form.Item
                  help={
                    touched.phones &&
                    touched.phones[index] &&
                    touched.phones[index].phone &&
                    errors.phones &&
                    errors.phones[index] &&
                    errors.phones[index].phone
                      ? errors.phones[index].phone
                      : ""
                  }
                  validateStatus={
                    touched.phones &&
                    touched.phones[index] &&
                    touched.phones[index].phone &&
                    errors.phones &&
                    errors.phones[index] &&
                    errors.phones[index].phone
                      ? "error"
                      : ""
                  }
                >
                  <Input
                    name={`phones.${index}.phone`}
                    value={phones.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyUp={(event) => phoneMaskBR(event)}
                    placeholder={t("Phone")}
                    disabled={isView}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  help={
                    touched.phones &&
                    touched.phones[index] &&
                    touched.phones[index].type &&
                    errors.phones &&
                    errors.phones[index] &&
                    errors.phones[index].type
                      ? errors.phones[index].type
                      : ""
                  }
                  validateStatus={
                    touched.phones &&
                    touched.phones[index] &&
                    touched.phones[index].type &&
                    errors.phones &&
                    errors.phones[index] &&
                    errors.phones[index].type
                      ? "error"
                      : ""
                  }
                >
                  <Select
                    name={`phones.${index}.type`}
                    value={phones.type || undefined}
                    onChange={(value) =>
                      handleChange({
                        target: { name: `phones.${index}.type`, value },
                      })
                    }
                    onBlur={handleBlur}
                    placeholder={t("Type_phone")}
                    disabled={isView}
                  >
                    {getTypes().map((type) => (
                      <Option value={type} key={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {allowPubliclyAccessible && (
                <Col xs={24} md={8}>
                  <Form.Item
                    label={t("Publicly_accessible_label")}
                    help={
                      touched.phones &&
                      touched.phones[index] &&
                      touched.phones[index].publicly_accessible &&
                      errors.phones &&
                      errors.phones[index] &&
                      errors.phones[index].publicly_accessible
                        ? errors.phones[index].publicly_accessible
                        : ""
                    }
                    validateStatus={
                      touched.phones &&
                      touched.phones[index] &&
                      touched.phones[index].publicly_accessible &&
                      errors.phones &&
                      errors.phones[index] &&
                      errors.phones[index].publicly_accessible
                        ? "error"
                        : ""
                    }
                  >
                    <Tooltip title={t("Publicly_accessible")}>
                      <Checkbox
                        name={`phones.${index}.publicly_accessible`}
                        checked={phones.publicly_accessible}
                        onChange={(e) =>
                          setFieldValue(
                            `phones[${index}].publicly_accessible`,
                            e.target.checked
                          )
                        }
                        onBlur={handleBlur}
                        disabled={isView}
                      />
                    </Tooltip>
                  </Form.Item>
                </Col>
              )}

              {!isView && (
                <Col xs={24} md={8}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => remove(index)}
                      danger
                      icon={<DeleteOutlined />}
                    >
                      {t("Delete_Phone")}
                    </Button>
                  </Form.Item>
                </Col>
              )}
              <Divider orientation="right">
                {t("Phone")} {index + 1}
              </Divider>
            </Row>
          ))}
          {!isView && (
            <Button
              type="default"
              onClick={() => {
                push({
                  phone: "",
                  type: "Residencial",
                  publicly_accessible: false,
                });
              }}
              icon={<PlusCircleOutlined />}
            >
              {t("New_Phone")}
            </Button>
          )}
        </>
      )}
    </FieldArray>
  );
};
