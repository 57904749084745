import {
  CompressOutlined,
  EnvironmentOutlined,
  ExpandOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  Tooltip,
} from "antd";
import { cnpj as cnpjValidator } from "cpf-cnpj-validator";
import { Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { AddressForm } from "../../components/AddressForm";
import { CustomButton } from "../../components/CustomButton";
import { EmailsForm } from "../../components/EmailsForm";
import HeaderInfosBase from "../../components/HeaderInfosBase";
import { PhonesForm } from "../../components/PhonesForm";
import { AuthContext } from "../../contexts/auth";
import { formatCnpj } from "../../utils/mask";
import { Map } from "../Maps/Map";
import { editFarm, getFarm, getSegments } from "./service/farm.service";
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;

// TODO ESSA TELA É USADA PARA EDITAR, CADASTRAR e VISUALIZAR, EU PRECISO GARANTIR QUE QUANDO O USUARIO ESTEJA
// VISUALIZANDO UMA FAZENDA PUBLICA E TROQUE PARA EDICAO, ELE NAO CONSIGA
export default function FarmCreatEdit() {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("NameIsRequired")),
    car: Yup.string().optional(),
    publicly_accessible: Yup.boolean().optional().default(false),
    cnpj: Yup.string().test("nomeDaValidacao", t("CNPJInvalid"), (value) => {
      if (value) {
        if (cnpjValidator.isValid(value)) {
          return true;
        }
        return false;
      }
      return true;
    }),
    segments: Yup.array().optional(),
    adresses: Yup.array()
      .optional()
      .of(
        Yup.object().shape({
          street: Yup.string().required(t("StreetIsRequired")),
          neighborhood: Yup.string().required(t("NeighborhoodIsRequired")),
          city: Yup.string().required(t("CityIsRequired")),
          state: Yup.string().required(t("StateIsRequired")),
          zipCode: Yup.string().optional().default(""),
          number: Yup.string().required(t("NumberIsRequired")),
          complement: Yup.string().optional().default(""),
          latitude: Yup.string().required(t("LatitudeIsRequired")),
          longitude: Yup.string().required(t("LongitudeIsRequired")),
          type: Yup.string().required(t("AddressTypeIsRequired")),
          publicly_accessible: Yup.boolean().optional().default(false),
        })
      )
      .optional(),
    phones: Yup.array()
      .of(
        Yup.object().shape({
          phone: Yup.string().required(t("PhoneIsRequired")),
          type: Yup.string().required(t("PhoneTypeIsRequired")),
          publicly_accessible: Yup.boolean().optional().default(false),
        })
      )
      .optional(),
    emails: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string().required(t("EmailIsRequired")),
          type: Yup.string().required(t("EmailTypeIsRequired")),
          publicly_accessible: Yup.boolean().optional().default(false),
        })
      )
      .optional(),
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [activeKey, setActiveKey] = useState([]);
  const [optionsSegments, setOptionsSegments] = useState([]);
  const location = useLocation();
  const isEditRoute = location.pathname.includes("edit");
  const isView = location.pathname.includes("view");
  const [initialValues, setInitialValues] = useState({
    name: "",
    cnpj: "",
    car: "",
    farmer_id: "",
    segments: [],
    adresses: [
      {
        street: "",
        neighborhood: "",
        city: "",
        state: "",
        zipCode: "",
        number: "",
        complement: "",
        latitude: "",
        longitude: "",
        type: "Residencial",
        publicly_accessible: false,
      },
    ],
    phones: [],
    emails: [],
  });

  const [infosHeader, setInfosHeader] = useState([
    {
      label: t("Name"),
      value: "",
      type: "label",
    },
    {
      label: "Car",
      value: "",
      type: "label",
    },
    {
      label: "CNPJ",
      value: "",
      type: "label",
    },
    {
      label: t("Publicly_accessible_label"),
      value: "",
      type: "tag",
    },
  ]);

  const findFarm = useCallback(async () => {
    if (isEditRoute || isView) {
      const data = await getFarm(id);
      if (
        data.length === 0 ||
        (isEditRoute && data.user_farmer_id !== user.user_id)
      ) {
        navigate("/farms");
      }
      setInitialValues(data);
      setInfosHeader([
        {
          label: t("Name"),
          value: data.name,
          type: "label",
        },
        {
          label: "Car",
          value: data.car,
          type: "label",
        },
        {
          label: "CNPJ",
          value: data.cnpj,
          type: "label",
        },
        // {
        //   label: t("Segments"),
        //   value: data.segments.length > 0 ? data.segments.join(" | ") : "",
        //   type: "label",
        // },
        {
          label: t("Publicly_accessible_label"),
          value: data.publicly_accessible ? t("Yes") : t("No"),
          type: "tag",
        },
      ]);
    }
  }, [id, isEditRoute, isView, navigate, user.user_id, t]);

  const findSegments = useCallback(async () => {
    const result = await getSegments();
    setOptionsSegments(result.map((x) => x.segment));
  }, []);

  useEffect(() => {
    findFarm();
    findSegments();
  }, [findFarm, findSegments]);

  async function saveForm(values) {
    try {
      const payload = {
        name: values.name,
        car: values.car,
        cnpj: values.cnpj,
        emails: values.emails,
        phones: values.phones,
        adresses: values.adresses,
        segments: values.segments,
        publicly_accessible: values.publicly_accessible,
      };
      await editFarm(payload, isEditRoute ? id : "null");
      if (!isEditRoute) navigate("/farms");
      await findFarm();
    } catch (error) {
      console.log("AQUI", error);
    }
  }

  const onChange = (key) => {
    setActiveKey(key);
  };

  function openPanels() {
    setActiveKey([1, 2, 3]);
  }

  function togglePanels() {
    if (activeKey.length === 3) {
      setActiveKey([]);
    } else {
      setActiveKey([1, 2, 3]);
    }
  }

  const genExtra = (key) => {
    switch (key) {
      case 1:
        return (
          <EnvironmentOutlined
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        );
      case 2:
        return (
          <PhoneOutlined
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        );
      case 3:
        return (
          <MailOutlined
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={saveForm}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form onSubmitCapture={handleSubmit}>
              <div style={{ position: "relative" }}>
                <HeaderInfosBase
                  linkRedirect={"/farms"}
                  data={infosHeader}
                  title={t("Infos_Farm")}
                  linkImage={
                    "https://img.freepik.com/fotos-premium/lindo-amanhecer-na-fazenda-no-brasil_70216-5417.jpg"
                  }
                />
              </div>
              <Row
                gutter={16}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col xs={24} md={8} style={{ marginBottom: "5px" }}>
                  <Form.Item
                    help={touched.name && errors.name ? errors.name : ""}
                    validateStatus={touched.name && errors.name ? "error" : ""}
                  >
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("Name")}
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} style={{ marginBottom: "5px" }}>
                  <Form.Item
                    help={touched.car && errors.car ? errors.car : ""}
                    validateStatus={touched.car && errors.car ? "error" : ""}
                  >
                    <Input
                      name="car"
                      value={values.car}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Car"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} style={{ marginBottom: "5px" }}>
                  <Form.Item
                    help={touched.cnpj && errors.cnpj ? errors.cnpj : ""}
                    validateStatus={touched.cnpj && errors.cnpj ? "error" : ""}
                  >
                    <Input
                      name="cnpj"
                      value={values.cnpj}
                      onChange={(event) => {
                        const formattedValue = formatCnpj(event);
                        setFieldValue("cnpj", formattedValue);
                      }}
                      onBlur={handleBlur}
                      onKeyUp={(event) => formatCnpj(event)}
                      placeholder="CNPJ"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={16}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col xs={24} md={8}>
                  <Form.Item
                    help={
                      touched.segments && errors.segments ? errors.segments : ""
                    }
                    validateStatus={
                      touched.segments && errors.segments ? "error" : ""
                    }
                  >
                    <Select
                      allowClear
                      mode="multiple"
                      value={values.segments || []}
                      disabled={isView}
                      onChange={(selectedValues) => {
                        handleChange({
                          target: {
                            name: "segments",
                            value: selectedValues,
                          },
                        });
                      }}
                      onBlur={handleBlur}
                      placeholder={t("Segments")}
                      showSearch
                    >
                      {optionsSegments.map((key) => (
                        <Option value={key} key={key}>
                          {key}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: "10px" }}>
                <Col xs={24} md={4}>
                  <Form.Item
                    label={t("Publicly_accessible_label")}
                    help={
                      touched.publicly_accessible && errors.publicly_accessible
                        ? errors.publicly_accessible
                        : ""
                    }
                    validateStatus={
                      touched.publicly_accessible && errors.publicly_accessible
                        ? "error"
                        : ""
                    }
                  >
                    <Tooltip title={t("Publicly_accessible")}>
                      <Checkbox
                        name="publicly_accessible"
                        disabled={isView}
                        checked={values.publicly_accessible}
                        onChange={(e) =>
                          setFieldValue("publicly_accessible", e.target.checked)
                        }
                        onBlur={handleBlur}
                      />
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>

              <Tabs defaultActiveKey="1">
                <TabPane tab={t("Info_basics")} key="1">
                  <Row justify="end" style={{ marginBottom: "15px" }}>
                    <Col>
                      <Button
                        type="dashed"
                        onClick={togglePanels}
                        icon={
                          activeKey.length === 3 ? (
                            <CompressOutlined />
                          ) : (
                            <ExpandOutlined />
                          )
                        }
                      >
                        {activeKey.length === 3 ? t("Colapse") : t("Expand")}
                      </Button>
                    </Col>
                  </Row>
                  <Collapse activeKey={activeKey} onChange={onChange}>
                    <Panel header={t("Adresses")} key="1" extra={genExtra(1)}>
                      <AddressForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        onlyOne={true}
                        allowDelete={false}
                        allowLatLong={true}
                        setFieldValue={setFieldValue}
                        isView={isView}
                      />
                    </Panel>
                    <Panel header={t("Phones")} key="2" extra={genExtra(2)}>
                      <PhonesForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        isView={isView}
                      />
                    </Panel>
                    <Panel header={t("Emails")} key="3" extra={genExtra(3)}>
                      <EmailsForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        isView={isView}
                      />
                    </Panel>
                  </Collapse>
                </TabPane>
                <TabPane tab={t("Map")} key="2">
                  <div style={{ height: 600, width: 800 }}>
                    <Map
                      handleMapClick={() => {}}
                      hidePreviousMarkers={true}
                      latitude={values.adresses.latitude}
                      longitude={values.adresses.longitude}
                    />
                  </div>
                </TabPane>
              </Tabs>

              <div style={{ marginTop: "32px" }}>
                {!isView && (
                  <CustomButton
                    isEditRoute={isEditRoute}
                    handleClick={openPanels}
                  ></CustomButton>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
