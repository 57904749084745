// components/productsFieldArray.js
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from "antd";
import { FieldArray } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

export const ProductsForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  onlyOne,
  allowDelete,
  allowLatLong,
  allowPubliclyAccessible = true,
  setFieldValue,
  isView = false,
}) => {
  const { Option } = Select;
  const { t } = useTranslation();

  const selectAfter = (
    <Select
      defaultValue="BRL"
      style={{
        width: 60,
      }}
    >
      <Option value="BRL">R$</Option>
      <Option value="USD">$</Option>
      <Option value="EUR">€</Option>
      <Option value="GBP">£</Option>
      <Option value="CNY">¥</Option>
    </Select>
  );

  function renderInput(
    x,
    index,
    product,
    handleChange,
    handleBlur,
    touched,
    errors,
    isView
  ) {
    switch (x.type) {
      case "date":
        return (
          <Tooltip title={x.placeholder}>
            <Input
              name={`products.${index}.${x.field}`}
              value={product[x.field]}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={x.placeholder}
              disabled={isView}
              type={x.type}
            />
          </Tooltip>
        );
      case "input":
        return (
          <Input
            name={`products.${index}.${x.field}`}
            value={product[x.field]}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={x.placeholder}
            disabled={isView}
            type={x.type}
          />
        );
      case "text_area":
        return (
          <TextArea
            name={`products.${index}.${x.field}`}
            value={product[x.field]}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={x.placeholder}
            disabled={isView}
            type={x.type}
          />
        );
      case "select_simple":
        return (
          <Select
            name={`products.${index}.${x.field}`}
            value={product[x.field] || undefined}
            disabled={isView}
            onChange={(value) =>
              handleChange({
                target: { name: `products.${index}.${x.field}`, value },
              })
            }
            onBlur={handleBlur}
            placeholder={x.placeholder}
          >
            {x.options.map((key) => (
              <Option value={key} key={key}>
                {key}
              </Option>
            ))}
          </Select>
        );
      case "text":
        return (
          <Input.TextArea
            name={`products.${index}.${x.field}`}
            value={product[x.field]}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={x.placeholder}
            disabled={isView}
          />
        );
      case "number":
        return (
          <InputNumber
            style={{ width: "100%" }}
            name={`products.${index}.${x.field}`}
            value={product[x.field]}
            onBlur={handleBlur}
            placeholder={x.placeholder}
            disabled={isView}
            step={0.01}
          />
        );
      case "coin":
        return (
          <InputNumber
            name={`products.${index}.${x.field}`}
            value={product[x.field]}
            onChange={(value) =>
              handleChange({
                target: { name: `adresses.${index}.type`, value },
              })
            }
            onBlur={handleBlur}
            placeholder={x.placeholder}
            disabled={isView}
            addonAfter={selectAfter}
            step={0.01}
          />
        );
      default:
        return (
          <Input
            name={`products.${index}.${x.field}`}
            value={product[x.field]}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={x.placeholder}
            disabled={isView}
            type={x.type}
          />
        );
    }
  }

  const fields = [
    {
      field: "identifier",
      placeholder: t("Identifier"),
      xs: 8,
      md: 4,
      type: "input",
      options: [],
      configs: [],
    },
    {
      field: "name",
      placeholder: t("Product"),
      xs: 8,
      md: 4,
      type: "input",
      options: [],
      configs: [],
    },
    {
      field: "description",
      placeholder: t("Description"),
      xs: 8,
      md: 4,
      type: "input",
      options: [],
      configs: [],
    },
    {
      field: "category",
      placeholder: t("Category"),
      xs: 8,
      md: 4,
      type: "select_simple",
      options: [
        "Equipamentos Agrícolas",
        "Sementes e Mudas",
        "Fertilizantes",
        "Produtos Veterinários",
        "Suplementos para Animais",
        "Ração e Alimentos para Animais",
        "Produtos Lácteos",
        "Queijos e Derivados",
        "Frios e Embutidos",
      ],
      configs: [],
    },
    {
      field: "supplier",
      placeholder: t("Supplier"),
      xs: 8,
      md: 4,
      type: "input",
      options: [],
      configs: [],
    },
    {
      field: "validity",
      placeholder: t("Validity"),
      xs: 8,
      md: 4,
      type: "date",
      options: [],
      configs: [],
    },
    {
      field: "price",
      placeholder: t("Price"),
      xs: 8,
      md: 4,
      type: "coin",
      options: [],
      configs: [],
    },
    {
      field: "unit",
      placeholder: t("Unit"),
      xs: 8,
      md: 4,
      type: "select_simple",
      options: [
        "Metro (m)",
        "Quilômetro (km)",
        "Centímetro (cm)",
        "Milímetro (mm)",
        "Pé (ft)",
        "Litro (L)",
        "Mililitro (mL)",
        "Grama (g)",
        "Quilograma (kg)",
        "Miligrama (mg)",
        "Segundo (s)",
        "Minuto (min)",
        "Hora (h)",
        "Dia (d)",
        "Kelvin (K)",
        "Celsius (°C)",
        "Fahrenheit (°F)",
      ],
      configs: [],
    },
    {
      field: "weight_volume",
      placeholder: t("Weight_volume"),
      xs: 8,
      md: 4,
      type: "number",
      options: [],
      configs: [],
    },
    {
      field: "obs",
      placeholder: t("Obs"),
      xs: 20,
      md: 12,
      type: "text_area",
      options: [],
      configs: [],
    },
  ];
  return (
    <FieldArray name="products">
      {({ remove, push }) => (
        <>
          {values.products?.map((product, index) => (
            <Row gutter={16} key={index}>
              {fields.map((x, indexInt) => (
                <Col xs={x.xs} md={x.md} key={indexInt}>
                  <Form.Item
                    help={
                      touched.products &&
                      touched.products[index] &&
                      touched.products[index][x.field] &&
                      errors.products &&
                      errors.products[index] &&
                      errors.products[index][x.field]
                        ? errors.products[index][x.field]
                        : ""
                    }
                    validateStatus={
                      touched.products &&
                      touched.products[index] &&
                      touched.products[index][x.field] &&
                      errors.products &&
                      errors.products[index] &&
                      errors.products[index][x.field]
                        ? "error"
                        : ""
                    }
                  >
                    {renderInput(
                      x,
                      index,
                      product,
                      handleChange,
                      handleBlur,
                      touched,
                      errors,
                      isView
                    )}
                  </Form.Item>
                </Col>
              ))}

              {allowDelete && (
                <Col xs={24} md={8}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => remove(index)}
                      danger
                      icon={<DeleteOutlined />}
                    >
                      {t("Delete_Product")}
                    </Button>
                  </Form.Item>
                </Col>
              )}
              <Divider orientation="right">Produto {index + 1}</Divider>
            </Row>
          ))}
          {!isView && !onlyOne && (
            <Button
              type="default"
              onClick={() => {
                push({
                  identifier: "",
                  name: "",
                  description: "",
                  category: "",
                  price: "",
                  unit: "",
                  supplier: "",
                  validity: "",
                  weight_volume: "",
                  obs: "",
                });
              }}
              icon={<PlusCircleOutlined />}
            >
              {t("Add_New_Product")}
            </Button>
          )}
        </>
      )}
    </FieldArray>
  );
};
