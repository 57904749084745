import { Card, Col, Divider, Row } from "antd";
import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const data = [
  { name: "Jan", sales: 4000 },
  { name: "Fev", sales: 3000 },
  { name: "Mar", sales: 2000 },
  { name: "Abr", sales: 2780 },
  { name: "Mai", sales: 1890 },
  { name: "Jun", sales: 2390 },
  { name: "Jul", sales: 3490 },
];

const Dashboard = () => {
  return (
    <div>
      <Row gutter={16}>
        <Col span={8}>
          <Card
            title="Vendas do mês"
            bordered={false}
            style={{ height: "100%" }}
          >
            <p>Total de vendas: R$ 10.000,00</p>
            <p>Última venda: 25/04/2022</p>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Despesas do mês"
            bordered={false}
            style={{ height: "100%" }}
          >
            <p>Total de despesas: R$ 5.000,00</p>
            <p>Última despesa: 20/04/2022</p>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Total de fornecedores"
            bordered={false}
            style={{ height: "100%" }}
          >
            <p>Total de fornecedores: 2</p>
            <p>Fornecedores ativos: 1</p>
          </Card>
        </Col>
      </Row>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Row gutter={16}>
        <Col span={8}>
          <Card
            title="Informações gerais"
            bordered={false}
            style={{ height: "100%" }}
          >
            <p>Total de fazendas: 5</p>
            <p>Total de animais: 200</p>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Produtividade"
            bordered={false}
            style={{ height: "100%" }}
          >
            <p>Total de leite produzido: 10.000 litros</p>
            <p>Total de carne produzido: 500 kg</p>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Animais" bordered={false} style={{ height: "100%" }}>
            <p>Total de Animais: 250</p>
          </Card>
        </Col>
      </Row>

      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Row gutter={16}>
        <Col span={24}>
          <Card
            title="Vendas por mês"
            bordered={false}
            style={{ height: "100%" }}
          >
            <LineChart
              width={600}
              height={300}
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="sales"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
