import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Input, Space } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import { FaFacebook, FaGoogle, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { AuthContext } from "../../../contexts/auth";
import "../entitlement.css";

export default function Login() {
  const { signIn, loadingAuth } = useContext(AuthContext);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .required("O e-mail é obrigatório"),
    password: Yup.string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
      .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
      .matches(/[0-9]/, "A senha deve conter pelo menos um número")
      .required("A senha é obrigatória"),
  });

  async function login(values) {
    await signIn(values.email, values.password);
  }

  return (
    <div className="login-container">
      <Card title="Login" className="login-card">
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={login}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
          }) => (
            <Form layout="vertical" onSubmitCapture={handleSubmit}>
              <Form.Item
                help={touched.email && errors.email ? errors.email : ""}
                validateStatus={touched.email && errors.email ? "error" : ""}
              >
                <Input
                  prefix={<UserOutlined />}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="E-mail"
                />
              </Form.Item>
              <Form.Item
                help={
                  touched.password && errors.password ? errors.password : ""
                }
                validateStatus={
                  touched.password && errors.password ? "error" : ""
                }
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Senha"
                />
              </Form.Item>
              <Form.Item>
                <div className="links-container">
                  <Link to="/register">Criar uma conta</Link>
                  <Link to="/retrieve_password_request">Recuperar senha</Link>
                </div>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {!loadingAuth ? "Entrar" : "Entrando ..."}
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
        <Divider>ou entre com</Divider>
        <div className="icon-container">
          <Space size="middle">
            <FaGoogle style={{ fontSize: 24, color: "#db4437" }} />
            <FaFacebook style={{ fontSize: 24, color: "#1877F2" }} />
            <FaLinkedin style={{ fontSize: 24, color: "#0A66C2" }} />
          </Space>
        </div>
        <Form.Item>
          <div className="links-container">
            <Link to="/confirm_registration">Confirmar Cadastro</Link>
          </div>
        </Form.Item>
      </Card>
    </div>
  );
}
