import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const CustomButton = ({
  isEditRoute,
  handleClick = () => {},
  isDefault = true,
  icon,
  text,
  danger = false,
  type = "primary",
}) => {
  const { t } = useTranslation();
  if (isDefault) {
    return (
      <Button
        onClick={() => handleClick()}
        type="primary"
        htmlType="submit"
        icon={isEditRoute ? <EditOutlined /> : <PlusCircleOutlined />}
      >
        {isEditRoute ? t("Edit") : t("Save")}
      </Button>
    );
  } else {
    return (
      <Button
        onClick={() => handleClick()}
        type={type}
        htmlType="submit"
        icon={icon}
        danger={danger}
      >
        {text}
      </Button>
    );
  }
};
