import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { AuthContext } from "../../../contexts/auth";
import "../entitlement.css";

export default function ConfirmRegistration() {
  const { confirmRegistration, loadingAuth } = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .required("O e-mail é obrigatório"),
    code: Yup.string().required("O código é obrigatório"),
  });

  async function confirmUserRegistration(values) {
    await confirmRegistration(values.email, values.code);
  }

  return (
    <div className="login-container">
      <Card title="Confirmar Registro" className="login-card">
        <Formik
          initialValues={{
            email: "",
            code: "",
          }}
          validationSchema={validationSchema}
          onSubmit={confirmUserRegistration}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
          }) => (
            <Form layout="vertical" onSubmitCapture={handleSubmit}>
              <Form.Item
                help={touched.email && errors.email ? errors.email : ""}
                validateStatus={touched.email && errors.email ? "error" : ""}
              >
                <Input
                  prefix={<UserOutlined />}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="E-mail"
                />
              </Form.Item>
              <Form.Item
                help={touched.code && errors.code ? errors.code : ""}
                validateStatus={touched.code && errors.code ? "error" : ""}
              >
                <Input
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Código"
                />
              </Form.Item>

              <Form.Item>
                <div className="links-container">
                  Já tem uma conta? <Link to="/login">Faça login</Link>
                </div>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {!loadingAuth ? "Confirmar Cadastro" : "Enviando ..."}
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}
