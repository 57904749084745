import { CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import MapSelectControl from "./MapSelectControl";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export const Map = ({
  handleMapClick = () => {},
  handleCloseButton = () => {},
  hideButton = false,
  hidePreviousMarkers = false,
  latitude = null,
  longitude = null,
}) => {
  const { t } = useTranslation();

  const [location, setLocation] = useState(null);
  const [zoom, setZoom] = useState(13);
  const [tileLayerUrl, setTileLayerUrl] = useState(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  );
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (latitude && longitude) {
      const loc = {
        lat: latitude,
        lng: longitude,
      };
      setLocation(loc);
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const loc = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setLocation(loc);
        setZoom(15);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleTileLayerChange = (value) => {
    setTileLayerUrl(value);
  };

  const AddMarkerToMap = () => {
    const map = useMap();
    map.on("click", (e) => {
      const newMarker = {
        lat: e.latlng.lat,
        lng: e.latlng.lng,
        message: `Lat: ${e.latlng.lat}, Lng: ${e.latlng.lng}`,
      };
      if (hidePreviousMarkers) {
        setMarkers([newMarker]);
      } else {
        setMarkers([...markers, newMarker]);
      }

      handleMapClick(newMarker.lat, newMarker.lng);
    });

    if (hidePreviousMarkers) {
      return (
        <>
          {markers.length > 0 && (
            <Marker key={0} position={[markers[0].lat, markers[0].lng]}>
              <Popup>{markers[0].message}</Popup>
            </Marker>
          )}
        </>
      );
    }

    return (
      <>
        {markers.map((marker, index) => (
          <Marker key={index} position={[marker.lat, marker.lng]}>
            <Popup>{marker.message}</Popup>
          </Marker>
        ))}
      </>
    );
  };

  if (!location) {
    return <div>Loading...</div>;
  }
  return (
    <MapContainer
      center={location}
      zoom={zoom}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        url={tileLayerUrl}
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={location} />
      {hideButton && (
        <Button
          onClick={handleCloseButton}
          icon={<CloseCircleOutlined />}
          style={{
            position: "absolute",
            top: "50px",
            right: "10px",
            zIndex: 1000,
            marginBottom: "10px", // Adicionado para criar espaço entre o botão e o MapSelectControl
          }}
        >
          {t("Close_Map")}
        </Button>
      )}
      <MapSelectControl onSelectChange={handleTileLayerChange} />
      <AddMarkerToMap />
    </MapContainer>
  );
};
