import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "../../components/Grid";

export default function SuppliersList() {
  const [suppliers, setSuppliers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [isFarmsPublics, setIsFarmsPublics] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchData(id) {
      try {
        return [
          {
            key: "1",
            identifier: "S123456",
            name: "Fornecedor 1",
            segment: "Venda de Adubos",
            longitude: "25.1510151",
            createdAt: `${new Date()}`,
          },
          {
            key: "2",
            name: "Fornecedor 2",
            identifier: "S123457",
            segment: "Venda de Ração",
            createdAt: `${new Date()}`,
          },
          {
            key: "3",
            name: "Fornecedor 3",
            identifier: "S123458",
            segment: "Venda de Produtos Agriculas",
            createdAt: `${new Date()}`,
          },
        ];
      } catch (error) {
        console.log("Erro ao buscar dados:", error);
      }
    }

    async function initial() {
      const data = await fetchData();
      setSuppliers(data);
    }

    if (location.pathname.includes("suppliers/publics")) {
      setIsFarmsPublics(true);
    } else {
      setIsFarmsPublics(false);
    }

    initial();
  }, [currentPage, pageSize, search, location.pathname]);

  const handleDelete = async (key) => {
    try {
      const updatedFarms = suppliers.filter((farm) => farm.key !== key);
      setSuppliers(updatedFarms);
    } catch (error) {
      console.log("Erro ao excluir fornecedor:", error);
    }
  };
  const handleAdd = () => {
    navigate("/suppliers/create");
  };

  const handleEdit = (key) => {
    navigate(`/suppliers/${key}/edit`);
  };

  const handleView = (key) => {
    navigate(`/suppliers/${key}/view`);
  };

  const handleChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <div>
      <Grid
        data={suppliers}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
        onChangePage={handleChangePage}
        onSearch={handleSearch}
        nameButtom={t("Add_Supplier")}
        showViewButton={isFarmsPublics}
        showEditButton={!isFarmsPublics}
        showDeleteButton={!isFarmsPublics}
        showCreateButton={!isFarmsPublics}
        placeholderSearch={t("Search_suppliers")}
        columns={[
          {
            title: t("Identifier"),
            dataIndex: "identifier",
            key: "identifier",
          },
          {
            title: t("Name"),
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: t("Segment"),
            dataIndex: "segment",
            key: "segment",
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: t("CreatedAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            render: (text, record) => (
              <span>{new Date(record.createdAt).toLocaleDateString()}</span>
            ),
          },
        ]}
      />
    </div>
  );
}
