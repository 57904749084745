import { LeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Row, Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HeaderInfosBase = ({
  linkRedirect,
  title,
  data = [],
  linkImage = "https://media.gettyimages.com/id/904558920/pt/foto/when-it-comes-to-farming-youve-gotta-be-hands-on.jpg?s=612x612&w=gi&k=20&c=3J3kj5W9gPGNfSLMMnWdn3JNIkQd-mPvFDJDU3d5kKk=",
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ position: "relative" }}>
      <Link to={linkRedirect}>
        <Button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1000,
          }}
          type="text"
          icon={<LeftOutlined />}
        >
          {t("To_go_back")}
        </Button>
      </Link>
      <Row style={{ marginBottom: "20px" }}>
        <Col xs={24}>
          <Card className="card-no-border">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={6}>
                <img
                  src={linkImage}
                  alt="Imagem"
                  style={{ width: "100%", height: "auto" }}
                />
              </Col>
              <Col xs={24} md={18}>
                <Descriptions title={title} layout="vertical">
                  {data.map((x) => (
                    <Descriptions.Item label={x.label}>
                      {(() => {
                        switch (x.type) {
                          case "tag":
                            return <Tag>{x.value}</Tag>;
                          case "outra opção":
                            return <span>Valor para outra opção</span>;
                          default:
                            return <span>{x.value}</span>;
                        }
                      })()}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HeaderInfosBase;
