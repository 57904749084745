export const phoneMaskBR = (event) => {
  if (event.target.value.length > 14) {
    const x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    event.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  } else {
    const x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
    event.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  }
};

export const phoneMaskBRDefault = (value) => {
  const x = value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
  return (value = !x[2]
    ? x[1]
    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : ""));
};

export function formatCpf(event) {
  event.target.value = event.target.value.replace(/\D/g, "");
  event.target.value = event.target.value.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    "$1.$2.$3-$4"
  );

  return event.target.value;
}

export function formatCnpj(event) {
  event.target.value = event.target.value.replace(/\D/g, "");
  event.target.value = event.target.value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );

  return event.target.value;
}

export function formatCpfDefault(value) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  return value;
}
