import { Select } from "antd";
import L from "leaflet";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useMap } from "react-leaflet";

const options = [
  {
    value: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    label: "OpenStreetMap",
  },
  {
    value:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    label: "Esri World Imagery",
  },
  {
    value: "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}.png",
    label: "Stadia Alidade Suave",
  },
  {
    value: "https://{s}.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png",
    label: "Hydda Completo",
  },
  {
    value: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
    label: "Humanitário",
  },
  {
    value: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
    label: "OpenTopoMap",
  },
  {
    value:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
    label: "CartoDB Claro",
  },
  {
    value:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png",
    label: "CartoDB Escuro",
  },
  {
    value:
      "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
    label: "CartoDB Voyager",
  },
  {
    value: "https://{s}.tile.thunderforest.com/transport/{z}/{x}/{y}.png",
    label: "Thunderforest Transporte",
  },
  {
    value: "https://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png",
    label: "Thunderforest Paisagem",
  },
  {
    value: "https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png",
    label: "Thunderforest Exteriores",
  },
  {
    value:
      "https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=your-access-token",
    label: "JawgMaps Ruas",
  },
  {
    value:
      "https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}{r}.png?access-token=your-access-token",
    label: "JawgMaps Terreno",
  },
  {
    value:
      "https://tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=your-access-token",
    label: "JawgMaps Claro",
  },
  {
    value:
      "https://tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=your-access-token",
    label: "JawgMaps Escuro",
  },
];

const MapSelectControl = ({ onSelectChange }) => {
  const map = useMap();
  const [selectedValue, setSelectedValue] = useState(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  ); // Define o valor padrão como "OpenStreetMap"

  useEffect(() => {
    const selectContainer = L.DomUtil.create("div");
    selectContainer.style.margin = "10px";
    selectContainer.style.marginTop = "10px";

    L.DomEvent.disableClickPropagation(selectContainer);
    L.DomEvent.disableScrollPropagation(selectContainer);

    const select = (
      <Select
        style={{ width: "100%" }}
        value={selectedValue}
        onChange={(value) => {
          setSelectedValue(value);
          onSelectChange(value);
        }}
      >
        {options.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    );

    const selectNode = L.DomUtil.create("div", "", selectContainer);

    // Utilize 'createRoot' do pacote 'react-dom/client'
    const root = createRoot(selectNode);
    root.render(select);

    const control = L.control({ position: "topright" });
    control.onAdd = () => selectContainer;
    control.addTo(map);

    return () => {
      control.remove();
      // Não há necessidade de desmontar o componente manualmente
    };
  }, [map, options, onSelectChange, selectedValue]);

  return null;
};

export default MapSelectControl;
