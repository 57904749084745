import axios from "axios";
import jwt from "jwt-decode";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export const AuthContext = createContext({});

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL_ENTITLEMENT,
});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function loadUser() {
      const storageUser = localStorage.getItem("userLogged");
      if (storageUser) {
        setUser(JSON.parse(storageUser));
        setLoading(false);
      }
      setLoading(false);
    }

    loadUser();
  }, []);

  async function signIn(email, password) {
    setLoadingAuth(true);
    const payload = {
      product_token: process.env.REACT_APP_PRODUCT_TOKEN,
      product_id: process.env.REACT_APP_PRODUT_ID,
    };

    try {
      const minhaString = `${email}:${password}`;
      const encoder = new TextEncoder();
      const data = encoder.encode(minhaString);
      const base64String = btoa(String.fromCharCode(...new Uint8Array(data)));

      const config = {
        headers: {
          Authorization: `Basic ${base64String}`,
        },
      };

      const result = await apiClient.post("v1/user/login", payload, config);
      setUser(jwt(result.data.data));
      storageUser(jwt(result.data.data), result.data.data);
      setLoadingAuth(false);
      navigate("/dashboard");
    } catch (error) {
      if (error.response.status < 500) {
        toast.info(error.response.data.message);
      } else {
        toast.error("Ops, algo deu errado");
      }
      setLoadingAuth(false);
    }
  }

  function updateUser(name, email, phone, whatsapp, userId) {
    setUser(null);
    setUser({ name, email, phone, whatsapp, user_id: userId });
  }

  async function confirmRegistration(email, code) {
    setLoadingAuth(true);
    const payload = {
      email: email,
      code: code,
      product_token: process.env.REACT_APP_PRODUCT_TOKEN,
      product_id: process.env.REACT_APP_PRODUT_ID,
    };

    try {
      await apiClient.patch("v1/user/activation-code", payload);
      setLoadingAuth(false);
      toast.success("Cadastro confirmado com sucesso");
      navigate("/login");
    } catch (error) {
      if (error.response.status < 500) {
        toast.info(error.response.data.message);
      } else {
        toast.error("Ops, algo deu errado");
      }
      setLoadingAuth(false);
    }
  }

  async function changePassword(email, password, confirm_password, code) {
    setLoadingAuth(true);
    const payload = {
      login: email,
      password: password,
      confirm_password: confirm_password,
      code: code,
      product_token: process.env.REACT_APP_PRODUCT_TOKEN,
      product_id: process.env.REACT_APP_PRODUT_ID,
    };

    try {
      await apiClient.patch("v1/user/password-recovery", payload);
      setLoadingAuth(false);
      toast.success("Troca de senha realizada com sucesso");
      navigate("/login");
    } catch (error) {
      if (error.response.status < 500) {
        toast.info(error.response.data.message);
      } else {
        toast.error("Ops, algo deu errado");
      }
      setLoadingAuth(false);
    }
  }

  async function retrievePasswordRequest(email) {
    setLoadingAuth(true);
    const payload = {
      login: email,
      product_token: process.env.REACT_APP_PRODUCT_TOKEN,
      product_id: process.env.REACT_APP_PRODUT_ID,
    };

    try {
      await apiClient.patch("v1/user/password-recovery-request", payload);
      setLoadingAuth(false);
      toast.success("Email com o novo código enviado");
      navigate("/change_password");
    } catch (error) {
      if (error.response.status < 500) {
        toast.info(error.response.data.message);
      } else {
        toast.error("Ops, algo deu errado");
      }
      setLoadingAuth(false);
    }
  }

  async function register(
    email,
    password,
    confirm_password,
    name,
    phone,
    whatsapp
  ) {
    setLoadingAuth(true);
    const payload = {
      email: email,
      name: name,
      phone: phone,
      password: password,
      confirm_password: confirm_password,
      whatsapp: whatsapp,
      product_token: process.env.REACT_APP_PRODUCT_TOKEN,
      product_id: process.env.REACT_APP_PRODUT_ID,
      language: "PT",
    };

    try {
      await apiClient.post("v1/user", payload);
      toast.success("Cadastro realizado com sucesso");
      setLoadingAuth(false);
      navigate("/confirm_registration");
    } catch (error) {
      if (error.response.status < 500) {
        toast.info(error.response.data.message);
      } else {
        toast.error("Ops, algo deu errado");
      }
      setLoadingAuth(false);
    }
  }

  function storageUser(data, token) {
    localStorage.setItem("userLogged", JSON.stringify(data));
    localStorage.setItem("token", token);
  }

  async function logout() {
    console.log("AQUI");
    localStorage.removeItem("userLogged");
    localStorage.removeItem("token");
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        signIn,
        register,
        logout,
        loadingAuth,
        loading,
        storageUser,
        setUser,
        updateUser,
        confirmRegistration,
        retrievePasswordRequest,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
