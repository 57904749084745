const lightBlueTheme = {
  primaryColor: "#1890ff", // Cor azul claro
  textColor: "#000",
  backgroundColor: "#f0f2f5",
  HEADER_BACKGROUND_COLOR: "#FFFFFF",
  SIDER_BACKGROUND_COLOR: "#2962ff",
  MENU_SELECTED_COLOR: "#2962ff",
  menuTextColor: "#000000",
};

export default lightBlueTheme;
