import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import BaseLayout from "../pages/BaseLayout";
import DashBoard from "../pages/Dashboard/dashboard";
import EmployeesCreatEdit from "../pages/Employees/EmployeesCreatEdit";
import EmployeesList from "../pages/Employees/EmployeesList";
import Perfil from "../pages/Farmer/Perfil";
import PerfilChangePassword from "../pages/Farmer/PerfilChangePassword";
import FarmCreatEdit from "../pages/Farms/FarmCreatEdit";
import FarmsList from "../pages/Farms/FarmsList";
import { Map } from "../pages/Maps/Map";
import SupplierCreatEdit from "../pages/Suppliers/SupplierCreatEdit";
import SuppliersList from "../pages/Suppliers/SuppliersList";
import ConfirmRegistration from "../pages/entitlement/CRegistration/ConfirmRegistration";
import ChangePassword from "../pages/entitlement/ChangePassword/ChangePassword";
import Login from "../pages/entitlement/Login/Login";
import Register from "../pages/entitlement/Register/Register";
import RetrievePasswordRequest from "../pages/entitlement/RetrievePasswordRequest/RetrievePasswordRequest";
import Private from "./Private";

function RoutesApp() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/confirm_registration" element={<ConfirmRegistration />} />
      <Route
        path="/retrieve_password_request"
        element={<RetrievePasswordRequest />}
      />
      <Route path="/change_password" element={<ChangePassword />} />

      <Route
        path="/dashboard"
        element={
          <Private>
            <BaseLayout breadcrumb={"DashBoard"} title={"DashBoard"}>
              <DashBoard />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/farms"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("MyProperties")}
              title={t("MyProperties")}
            >
              <FarmsList />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/farms/publics"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("PublicProperties")}
              title={t("PublicProperties")}
            >
              <FarmsList />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/farms/create"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("Properties_Creation")}
              title={t("Creation")}
            >
              <FarmCreatEdit />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/farms/:id/edit"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("Properties_Editing")}
              title={t("Property_Editing")}
            >
              <FarmCreatEdit />
            </BaseLayout>
          </Private>
        }
      />
      <Route
        path="/farms/:id/view"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("Properties_View")}
              title={t("View_Property")}
            >
              <FarmCreatEdit />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/suppliers"
        element={
          <Private>
            <BaseLayout breadcrumb={t("Suppliers")} title={t("Suppliers")}>
              <SuppliersList />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/suppliers/publics"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("PublicSuppliers")}
              title={t("PublicSuppliers")}
            >
              <SuppliersList />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/suppliers/create"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("Supplier_Creation")}
              title={t("Creation")}
            >
              <SupplierCreatEdit />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/suppliers/:id/edit"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("Supplier_Edition")}
              title={t("Supplier_Edition_1")}
            >
              <SupplierCreatEdit />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/suppliers/:id/view"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("Supplier_View")}
              title={t("View_Supplier")}
            >
              <SupplierCreatEdit />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/employees"
        element={
          <Private>
            <BaseLayout breadcrumb={t("Employees")} title={t("Employees")}>
              <EmployeesList />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/employees/create"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("Employee_Creation")}
              title={t("Creation")}
            >
              <EmployeesCreatEdit />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/employees/:id/edit"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("Employee_Editing")}
              title={t("Employee_Editing")}
            >
              <EmployeesCreatEdit />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/employees/:id/view"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("Employee_View")}
              title={t("Employee_View")}
            >
              <EmployeesCreatEdit />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/maps"
        element={
          <Private>
            <BaseLayout breadcrumb={t("Map")} title={t("Map")}>
              <Map />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="user/:id/perfil"
        element={
          <Private>
            <BaseLayout breadcrumb={t("User_Profile")} title={t("Perfil")}>
              <Perfil />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="user/:id/perfil/change_password"
        element={
          <Private>
            <BaseLayout
              breadcrumb={t("User_Profile_Password_Change")}
              title={t("Change_Password")}
            >
              <PerfilChangePassword />
            </BaseLayout>
          </Private>
        }
      />

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default RoutesApp;
