import React from "react";
import Dashboard from "./Base";

const BaseLayout = ({ children, breadcrumb, title }) => {
  return (
    <Dashboard breadcrumb={breadcrumb} title={title}>
      {children}
    </Dashboard>
  );
};

export default BaseLayout;
