import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Infos_Farm: "Information of the Farm",
      EnterYourNeed: "Register your need",
      NewItem: "Add",
      ClearSelection: "Clear selection",
      My_Perfil: "My Profile",
      Additional_Information: "Additional information",
      Needs: "Needs",
      Behaviors: "Behaviors",
      Yes: "Yes",
      No: "No",
      This_Action_Is_Irreversible: "This action is irreversible.",
      Title_Modal_Delete: "Are you sure you want to delete this item?",
      EmailIsRequired: "Email is required",
      InvalidEmail: "Invalid email",
      CPFInvalid: "Invalid CPF",
      Segments: "Segments",
      NameIsRequired: "Name is required",
      CNPJInvalid: "Invalid CNPJ",
      StreetIsRequired: "Street is required",
      NeighborhoodIsRequired: "Neighborhood is required",
      CityIsRequired: "City is required",
      StateIsRequired: "State is required",
      NumberIsRequired: "Number is required",
      LatitudeIsRequired: "Latitude is required",
      LongitudeIsRequired: "Longitude is required",
      AddressTypeIsRequired: "Address type is required",
      PhoneIsRequired: "Phone is required",
      PhoneTypeIsRequired: "Phone type is required",
      EmailTypeIsRequired: "Email type is required",
      MyProperty: "My property",
      Unit: "Unit of measurement",
      Add_New_Product: "Add New Product",
      Delete_Product: "Delete Product",
      Spanish: "Spanish",
      French: "French",
      Search_employees: "Search Employees",
      Add_Collaborators: "Add Collaborators",
      Change_Password: "Password Change",
      User_Profile_Password_Change: "User / Profile / Password Change",
      User_Profile: "User Profile",
      Perfil: "Profile",
      Employee_View: "View Employee",
      Employee_Editing: "Employee Editing",
      Employee_Creation: "Employee Creation",
      View_Supplier: "View Supplier",
      Supplier_View: "Supplier / View",
      Supplier_Edition_1: "Supplier Editing",
      Supplier_Edition: "Supplier / Edition",
      Supplier_Creation: "Supplier / Creation",
      View_Property: "View Property",
      Properties_View: "Properties / View",
      Property_Editing: "Property Editing",
      Properties_Editing: "Properties / Edition",
      Creation: "Creation",
      Properties_Creation: "Properties / Creation",
      Search_suppliers: "Search Suppliers",
      Add_Supplier: "Add Supplier",
      Products: "Products",
      Close_Map: "Close Map",
      Lat_Long: "Latitude and Longitude",
      Search_Farms: "Search Farms",
      Add_Farm: "Add Farm",
      Confirm_Password: "Confirm Password",
      Password: "Password",
      Change: "Change",
      Nikname: "Nickname",
      To_go_back: "Go Back",
      Name_collaborator: "Collaborator Name",
      CreatedAt: "Created At",
      Responsibility: "Responsibility",
      Functions: "Functions",
      Emails: "Emails",
      Phones: "Phones",
      Adresses: "Addresses",
      Expand: "Expand",
      Colapse: "Collapse",
      Info_basics: "Basic Information",
      Segment: "Segment",
      Name: "Name",
      Obs: "Note",
      Price: "Price",
      Weight_volume: "Weight | Volume",
      Validity: "Validity",
      Supplier: "Supplier",
      Category: "Category",
      Description: "Description",
      Product: "Product",
      Identifier: "Identifier",
      New_Phone: "Add phone",
      Delete_Phone: "Delete phone",
      Type_phone: "Type of phone",
      Phone: "Phone",
      View: "View",
      Delete: "Delete",
      Actions: "Actions",
      New_email: "Add new email",
      Delete_email: "Delete email",
      Type_Email: "Type of email",
      Email: "Email",
      Edit: "Edit",
      Save: "Save",
      New_address: "Add new address",
      Address: "Address",
      Delete_address: "Delete address",
      Publicly_accessible_label: "Publicly accessible",
      Publicly_accessible:
        "By selecting this option, the data in this form becomes publicly accessible to others. Sensitive information such as CPF will not be displayed.",
      Type_of_address: "Type of address",
      City: "City",
      State: "State",
      Zipcode: "Zip Code",
      Longitude: "Longitude",
      Latitude: "Latitude",
      Complement: "Complement",
      Street: "Street",
      Number: "Number",
      Neighborhood: "Neighborhood",
      Exit: "Logout",
      ChangePassword: "Change Password",
      MyPerfil: "My Profile",
      FlagBrasil: "Brazil Flag",
      FlagEua: "USA Flag",
      Portuguese: "Portuguese",
      English: "English",
      Dashboard: "Dashboard",
      Map: "Map",
      Property: "Property",
      MyProperties: "My Properties",
      PublicProperties: "Public Properties",
      Suppliers: "Suppliers",
      MySuppliers: "My Suppliers",
      PublicSuppliers: "Public Suppliers",
      Employees: "Employees",
      Animals: "Animals",
      Logout: "Logout",
    },
  },
  pt: {
    translation: {
      Infos_Farm: "Informações da Fazenda",
      ClearSelection: "Limpar seleção",
      Enter_your_need: "Cadastre sua necessidade",
      New_item: "Adicionar",
      My_Perfil: "Meu Perfil",
      Additional_Information: "Informações adicionais",
      Needs: "Necessidades",
      Behaviors: "Comportamentos",
      Yes: "Sim",
      No: "Não",
      This_Action_Is_Irreversible: "Essa ação é irreversível.",
      Title_Modal_Delete: "Você tem certeza que deseja excluir este item?",
      EmailIsRequired: "Email é obrigatório",
      InvalidEmail: "Email inválido",
      CPFInvalid: "CPF inválido",
      Segments: "Segmentos",
      NameIsRequired: "Nome é obrigatório",
      CNPJInvalid: "CNPJ inválido",
      StreetIsRequired: "A rua é obrigatória",
      NeighborhoodIsRequired: "O bairro é obrigatório",
      CityIsRequired: "A cidade é obrigatória",
      StateIsRequired: "O estado é obrigatório",
      NumberIsRequired: "O número é obrigatório",
      LatitudeIsRequired: "A latitude é obrigatória",
      LongitudeIsRequired: "A longitude é obrigatória",
      AddressTypeIsRequired: "O tipo de endereço é obrigatório",
      PhoneIsRequired: "O telefone é obrigatório",
      PhoneTypeIsRequired: "O tipo de telefone é obrigatório",
      EmailTypeIsRequired: "O tipo de email é obrigatório",
      MyProperty: "Minha propriedade",
      Unit: "Unidade de medida",
      Add_New_Product: "Adicionar novo produto",
      Delete_Product: "Deletar Produto",
      Spanish: "Espanhol",
      French: "Francês",
      Search_employees: "Pesquisar Colaboradoes",
      Add_Collaborators: "Adicionar Colaboradoes",
      Change_Password: "Troca de Senha",
      User_Profile_Password_Change: "Usuário / Perfil / Troca de Senha",
      User_Profile: "Perfil do Usuário",
      Perfil: "Perfil",
      Employee_View: "Visualizar Colaborador",
      Employee_Editing: "Edição de Colaborador",
      Employee_Creation: "Criação de Colaborador",
      View_Supplier: "Visualizar Fornecedor",
      Supplier_View: "Fornecedor / Visualizar",
      Supplier_Edition_1: "Edição de Fornecedor",
      Supplier_Edition: "Fornecedor / Edição",
      Supplier_Creation: "Fornecedor / Criação",
      View_Property: "Visualizar Propriedade",
      Properties_View: "Propriedades / Visualizar",
      Property_Editing: "Edição de Propriedade",
      Properties_Editing: "Propriedades / Edição",
      Creation: "Criação",
      Properties_Creation: "Propriedades / Criação",
      Search_suppliers: "Pesquisar Fornecedores",
      Add_Supplier: "Adicionar Fornecedor",
      Products: "Produtos",
      Close_Map: "Fechar Mapa",
      Lat_Long: "Latitude e Longitude",
      Search_Farms: "Pesquisar Fazendas",
      Add_Farm: "Adicionar Fazenda",
      Confirm_Password: "Confirmar Senha",
      Password: "Senha",
      Change: "Alterar",
      Nikname: "Apelido",
      To_go_back: "Voltar",
      Name_collaborator: "Nome do Colaborador",
      CreatedAt: "Criado em",
      Responsibility: "Cargo",
      Functions: "Funções",
      Emails: "Emails",
      Phones: "Telefones",
      Adresses: "Endereços",
      Expand: "Expandir",
      Colapse: "Colapsar",
      Info_basics: "Informações Básicas",
      Segment: "Segmento",
      Name: "Nome",
      Obs: "Observação",
      Price: "Preço",
      Weight_volume: "Peso | Volume",
      Validity: "Validade",
      Supplier: "Fornecedor",
      Category: "Categoria",
      Description: "Descricao",
      Product: "Produto",
      Identifier: "Identificador",
      New_Phone: "Adicionar telefone",
      Delete_Phone: "Deletar telefone",
      Type_phone: "Tipo de telefone",
      Phone: "Telefone",
      View: "Visualizar",
      Delete: "Deletar",
      Actions: "Ações",
      New_email: "Adicionar Novo Email",
      Delete_email: "Deletar Email",
      Type_Email: "Tipo de Email",
      Email: "Email",
      Edit: "Editar",
      Save: "Salvar",
      New_address: "Novo endereço",
      Address: "Endereço",
      Delete_address: "Deletar endereço",
      Publicly_accessible_label: "Publicamente acessivel",
      Publicly_accessible:
        "Ao marcar esta opção, os dados deste formulário ficam publicamente acessíveis a outras pessoas. Informações como CPF não serão exibidas.",
      Type_of_address: "Tipo de endereço",
      City: "Cidade",
      State: "Estado",
      Zipcode: "CEP",
      Longitude: "Longitude",
      Latitude: "Latitude",
      Complement: "Complemento",
      Street: "Rua",
      Number: "Número",
      Neighborhood: "Bairro",
      Exit: "Sair",
      ChangePassword: "Alteracao de Senha",
      MyPerfil: "Meu Perfil",
      FlagBrasil: "Bandeira do Brasil",
      FlagEua: "Bandeiro dos EUA",
      Portuguese: "Português",
      English: "Inglês",
      Dashboard: "Dashboard",
      Map: "Mapa",
      Property: "Propriedade",
      MyProperties: "Minhas Propriedades",
      PublicProperties: "Propriedades Públicas",
      Suppliers: "Fornecedores",
      MySuppliers: "Meus fornecedores",
      PublicSuppliers: "Fornecedores públicos",
      Employees: "Colaboradores",
      Animals: "Animais",
      Logout: "Sair",
      // ...
    },
  },
  fr: {
    translation: {
      Infos_Farm: "Informations de la ferme",
      EnterYourNeed: "Enregistrer votre besoin",
      NewItem: "Ajouter",
      ClearSelection: "Effacer la sélection",
      My_Perfil: "Mon Profil",
      Additional_Information: "Informations supplémentaires",
      Needs: "Besoin",
      Behaviors: "Comportements",
      Yes: "Oui",
      No: "Non",
      This_Action_Is_Irreversible: "Cette action est irréversible.",
      Title_Modal_Delete: "Êtes-vous sûr de vouloir supprimer cet élément?",
      EmailIsRequired: "L'email est requis",
      InvalidEmail: "Email invalide",
      CPFInvalid: "CPF invalide",
      Segments: "Segments",
      NameIsRequired: "Le nom est requis",
      CNPJInvalid: "CNPJ invalide",
      StreetIsRequired: "La rue est requise",
      NeighborhoodIsRequired: "Le quartier est requis",
      CityIsRequired: "La ville est requise",
      StateIsRequired: "L'état est requis",
      NumberIsRequired: "Le numéro est requis",
      LatitudeIsRequired: "La latitude est requise",
      LongitudeIsRequired: "La longitude est requise",
      AddressTypeIsRequired: "Le type d'adresse est requis",
      PhoneIsRequired: "Le téléphone est requis",
      PhoneTypeIsRequired: "Le type de téléphone est requis",
      EmailTypeIsRequired: "Le type d'email est requis",
      MyProperty: "Ma propriété",
      Unit: "Unité de mesure",
      Add_New_Product: "Ajouter un nouveau produit",
      Delete_Product: "Supprimer le produit",
      Search_employees: "Rechercher des collaborateurs",
      Add_Collaborators: "Ajouter des collaborateurs",
      Change_Password: "Changer le mot de passe",
      User_Profile_Password_Change:
        "Utilisateur / Profil / Changer le mot de passe",
      User_Profile: "Profil de l'utilisateur",
      Perfil: "Profil",
      Employee_View: "Afficher le collaborateur",
      Employee_Editing: "Modification du collaborateur",
      Employee_Creation: "Création du collaborateur",
      View_Supplier: "Voir le fournisseur",
      Supplier_View: "Fournisseur / Affichage",
      Supplier_Edition_1: "Modification du fournisseur",
      Supplier_Edition: "Fournisseur / Modification",
      Supplier_Creation: "Fournisseur / Création",
      View_Property: "Voir la propriété",
      Properties_View: "Propriétés / Affichage",
      Property_Editing: "Modification de la propriété",
      Properties_Editing: "Propriétés / Modification",
      Creation: "Création",
      Properties_Creation: "Propriétés / Création",
      Search_suppliers: "Rechercher des fournisseurs",
      Add_Supplier: "Ajouter un fournisseur",
      Products: "Produits",
      Close_Map: "Fermer la carte",
      Lat_Long: "Latitude et longitude",
      Search_Farms: "Rechercher des fermes",
      Add_Farm: "Ajouter une ferme",
      Confirm_Password: "Confirmer le mot de passe",
      Password: "Mot de passe",
      Change: "Modifier",
      Nikname: "Surnom",
      To_go_back: "Retour",
      Name_collaborator: "Nom du collaborateur",
      CreatedAt: "Créé à",
      Responsibility: "Responsabilité",
      Functions: "Fonctions",
      Emails: "Emails",
      Phones: "Téléphones",
      Adresses: "Adresses",
      Expand: "Développer",
      Colapse: "Réduire",
      Info_basics: "Informations de base",
      Segment: "Segment",
      Name: "Nome",
      Obs: "Observation",
      Price: "Prix",
      Weight_volume: "Poids | Volume",
      Validity: "Validité",
      Supplier: "Fournisseur",
      Category: "Catégorie",
      Description: "Description",
      Product: "Produit",
      Identifier: "Identifiant",
      New_Phone: "Ajouter un téléphone",
      Delete_Phone: "Supprimer le téléphone",
      Type_phone: "Type de téléphone",
      Phone: "Téléphone",
      View: "Afficher",
      Delete: "Supprimer",
      Actions: "Actions",
      New_email: "Ajouter un nouvel e-mail",
      Delete_email: "Supprimer l'e-mail",
      Type_Email: "Type d'e-mail",
      Email: "Email",
      Edit: "Modifier",
      Save: "Enregistrer",
      New_address: "Nouvelle adresse",
      Address: "Adresse",
      Delete_address: "Supprimer l'adresse",
      Publicly_accessible_label: "Accessibilité publique",
      Publicly_accessible:
        "En cochant cette option, les données de ce formulaire seront accessibles au public. Les informations telles que le CPF ne seront pas affichées.",
      Type_of_address: "Type d'adresse",
      City: "Ville",
      State: "État",
      Zipcode: "Code postal",
      Longitude: "Longitude",
      Latitude: "Latitude",
      Complement: "Complément",
      Street: "Rue",
      Number: "Numéro",
      Neighborhood: "Quartier",
      Exit: "Quitter",
      ChangePassword: "Changer le mot de passe",
      MyPerfil: "Mon profil",
      FlagBrasil: "Drapeau du Brésil",
      FlagEua: "Drapeau des États-Unis",
      Portuguese: "Portugais",
      English: "Anglais",
      Spanish: "Espagnol",
      French: "Français",
      Dashboard: "Tableau de bord",
      Map: "Carte",
      Property: "Propriété",
      MyProperties: "Mes propriétés",
      PublicProperties: "Propriétés publiques",
      Suppliers: "Fournisseurs",
      MySuppliers: "Mes fournisseurs",
      PublicSuppliers: "Fournisseurs publics",
      Employees: "Employés",
      Animals: "Animaux",
      Logout: "Déconnexion",
    },
  },
  es: {
    translation: {
      Infos_Farm: "Información de la granja",
      EnterYourNeed: "Registra tu necesidad",
      NewItem: "Agregar",
      ClearSelection: "Borrar selección",
      My_Perfil: "Mi Perfil",
      Additional_Information: "Información adicional",
      Needs: "Necesidades",
      Behaviors: "Comportamientos",
      Yes: "Sí",
      No: "No",
      This_Action_Is_Irreversible: "Esta acción es irreversible.",
      Title_Modal_Delete: "¿Estás seguro de que deseas eliminar este artículo?",
      EmailIsRequired: "El email es obligatorio",
      InvalidEmail: "Email inválido",
      CPFInvalid: "CPF inválido",
      Segments: "Segmentos",
      NameIsRequired: "Se requiere el nombre",
      CNPJInvalid: "CNPJ inválido",
      StreetIsRequired: "Se requiere la calle",
      NeighborhoodIsRequired: "Se requiere el barrio",
      CityIsRequired: "Se requiere la ciudad",
      StateIsRequired: "Se requiere el estado",
      NumberIsRequired: "Se requiere el número",
      LatitudeIsRequired: "Se requiere la latitud",
      LongitudeIsRequired: "Se requiere la longitud",
      AddressTypeIsRequired: "Se requiere el tipo de dirección",
      PhoneIsRequired: "Se requiere el teléfono",
      PhoneTypeIsRequired: "Se requiere el tipo de teléfono",
      EmailTypeIsRequired: "Se requiere el tipo de correo electrónico",
      MyProperty: "Mi propiedad",
      Unit: "Unidad de medida",
      Add_New_Product: "Agregar nuevo producto",
      Delete_Product: "Eliminar producto",
      Spanish: "Español",
      French: "Francés",
      Search_employees: "Buscar colaboradores",
      Add_Collaborators: "Agregar colaboradores",
      Change_Password: "Cambiar contraseña",
      User_Profile_Password_Change: "Usuario / Perfil / Cambio de contraseña",
      User_Profile: "Perfil de usuario",
      Perfil: "Perfil",
      Employee_View: "Ver colaborador",
      Employee_Editing: "Edición de colaborador",
      Employee_Creation: "Creación de colaborador",
      View_Supplier: "Ver proveedor",
      Supplier_View: "Proveedor / Ver",
      Supplier_Edition_1: "Edición de proveedor",
      Supplier_Edition: "Proveedor / Edición",
      Supplier_Creation: "Proveedor / Creación",
      View_Property: "Ver propiedad",
      Properties_View: "Propiedades / Ver",
      Property_Editing: "Edición de propiedad",
      Properties_Editing: "Propiedades / Edición",
      Creation: "Creación",
      Properties_Creation: "Propiedades / Creación",
      Search_suppliers: "Buscar proveedores",
      Add_Supplier: "Agregar proveedor",
      Products: "Productos",
      Close_Map: "Cerrar mapa",
      Lat_Long: "Latitud y longitud",
      Search_Farms: "Buscar granjas",
      Add_Farm: "Agregar granja",
      Confirm_Password: "Confirmar contraseña",
      Password: "Contraseña",
      Change: "Cambiar",
      Nikname: "Apodo",
      To_go_back: "Volver",
      Name_collaborator: "Nombre del colaborador",
      CreatedAt: "Creado en",
      Responsibility: "Cargo",
      Functions: "Funciones",
      Emails: "Correos electrónicos",
      Phones: "Teléfonos",
      Adresses: "Direcciones",
      Expand: "Expandir",
      Colapse: "Colapsar",
      Info_basics: "Información básica",
      Segment: "Segmento",
      Name: "Nombre",
      Obs: "Observación",
      Price: "Precio",
      Weight_volume: "Peso | Volume",
      Validity: "Validez",
      Supplier: "Proveedor",
      Category: "Categoría",
      Description: "Descripción",
      Product: "Producto",
      Identifier: "Identificador",
      New_Phone: "Agregar teléfono",
      Delete_Phone: "Eliminar teléfono",
      Type_phone: "Tipo de teléfono",
      Phone: "Teléfono",
      View: "Ver",
      Delete: "Eliminar",
      Actions: "Acciones",
      New_email: "Agregar correo electrónico",
      Delete_email: "Eliminar correo electrónico",
      Type_Email: "Tipo de correo electrónico",
      Email: "Correo electrónico",
      Edit: "Editar",
      Save: "Guardar",
      New_address: "Nueva dirección",
      Address: "Dirección",
      Delete_address: "Eliminar dirección",
      Publicly_accessible_label: "Accesible públicamente",
      Publicly_accessible:
        "Al marcar esta opción, los datos de este formulario se hacen públicamente accesibles a otras personas. La información como el CPF no se mostrará.",
      Type_of_address: "Tipo de dirección",
      City: "Ciudad",
      State: "Estado",
      Zipcode: "Código postal",
      Longitude: "Longitud",
      Latitude: "Latitud",
      Complement: "Complemento",
      Street: "Calle",
      Number: "Número",
      Neighborhood: "Barrio",
      Exit: "Salir",
      ChangePassword: "Cambio de contraseña",
      MyPerfil: "Mi perfil",
      FlagBrasil: "Bandera de Brasil",
      FlagEua: "Bandera de los Estados Unidos",
      Portuguese: "Portugués",
      English: "Inglés",
      Dashboard: "Tablero",
      Map: "Mapa",
      Property: "Propiedad",
      MyProperties: "Mis propiedades",
      PublicProperties: "Propiedades públicas",
      Suppliers: "Proveedores",
      MySuppliers: "Mis proveedores",
      PublicSuppliers: "Proveedores públicos",
      Employees: "Empleados",
      Animals: "Animales",
      Logout: "Cerrar sesión",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pt",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
