import { ShopOutlined } from "@ant-design/icons";
import { Modal, Tag } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "../../components/Grid";
import { AuthContext } from "../../contexts/auth";
import { deleteFarm, getFarms } from "./service/farm.service";

export default function FarmsList() {
  const { user } = useContext(AuthContext);
  const [farms, setFarms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [isFarmsPublics, setIsFarmsPublics] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname.includes("farms/publics")) {
      setIsFarmsPublics(true);
    } else {
      setIsFarmsPublics(false);
    }
  }, [location.pathname]);

  const findFarms = useCallback(async () => {
    setLoading(true);
    const result = await getFarms(
      pageSize,
      currentPage,
      isFarmsPublics,
      search,
      sortOrder,
      sortField
    );
    result.result.map((x) => (x.key = x._id));
    setFarms(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [pageSize, currentPage, isFarmsPublics, search, sortOrder, sortField]);

  useEffect(() => {
    findFarms();
  }, [findFarms]);

  const handleDelete = async (key) => {
    Modal.confirm({
      title: t("Title_Modal_Delete"),
      content: t("This_Action_Is_Irreversible"),
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk: async () => {
        await deleteFarm(key);
        await findFarms();
      },
    });
  };

  const handleAdd = () => navigate("/farms/create");

  const handleEdit = (key) => navigate(`/farms/${key}/edit`);

  const handleView = (key) => navigate(`/farms/${key}/view`);

  const handleChangePage = (page, pageSize, sorter) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      setSortOrder(sorter.order === "ascend" ? 1 : -1);
      setSortField(sorter.field);
    }
  };

  return (
    <div>
      <Grid
        data={farms}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
        onChangePage={handleChangePage}
        onSearch={handleSearch}
        onChange={handleTableChange}
        nameButtom={t("Add_Farm")}
        showViewButton={isFarmsPublics}
        showEditButton={!isFarmsPublics}
        showDeleteButton={!isFarmsPublics}
        showCreateButton={!isFarmsPublics}
        placeholderSearch={t("Search_Farms")}
        loading={loading}
        columns={[
          {
            title: t("Identifier"),
            dataIndex: "identifier",
            align: "center",
            sorter: true,
            render: (text, record) => {
              if (record.user_farmer_id === user.user_id) {
                return (
                  <Tag icon={<ShopOutlined />} color="success">
                    {record.identifier}
                  </Tag>
                );
              } else {
                return <Tag color="processing">{record.identifier}</Tag>;
              }
            },
          },
          {
            title: t("Name"),
            dataIndex: "name",
            sorter: true,
            render: (text, record) => {
              if (record.user_farmer_id !== user.user_id) {
                return <span key={record._id}>{record.name}</span>;
              } else if (isFarmsPublics) {
                return (
                  <span key={record._id}>
                    {record.name}{" "}
                    <Tag icon={<ShopOutlined />} color="success">
                      {t("MyProperty")}
                    </Tag>
                  </span>
                );
              } else {
                return <span key={record._id}>{record.name}</span>;
              }
            },
          },
          {
            title: t("CreatedAt"),
            dataIndex: "createdAt",
            sorter: true,
            render: (text, record) => (
              <span key={record.createdAt}>
                {new Date(record.createdAt).toLocaleDateString()}
              </span>
            ),
          },
          {
            title: t("Lat_Long"),
            render: (text, record) => {
              if (record.adresses.length !== 0) {
                return (
                  <span key={record.adresses[0].latitude}>
                    {record.adresses[0].latitude},{" "}
                    {record.adresses[0].longitude}
                  </span>
                );
              } else {
                return (
                  <span>
                    <i>Publicamente inacessível.</i>
                  </span>
                );
              }
            },
          },
        ]}
      />
    </div>
  );
}
