import axiosPlataform from "../../../utils/axiosInterceptorPlataform";

export const getSegments = async () => {
  const result = await axiosPlataform.get(`v1/segments`);
  return result.data.data;
};

export const editFarm = async (payload, farmId) => {
  return await axiosPlataform.put(`v1/farm/${farmId}`, payload);
};

export const deleteFarm = async (farmId) => {
  return await axiosPlataform.delete(`v1/farm/${farmId}`);
};

export const getFarm = async (farmId) => {
  const result = await axiosPlataform.get(`v1/farm/${farmId}`);
  return result.data.data;
};

export const getFarms = async (
  limit,
  page,
  isFarmsPublics,
  search,
  sortDirection,
  sortField
) => {
  const result = await axiosPlataform.get(
    `v1/farm?limit=${limit}&page=${page}&publicly_accessible=${isFarmsPublics}&search=${search}&sort_field=${sortField}&sort_direction=${sortDirection}`
  );
  return result.data.data;
};
