import {
  CompressOutlined,
  EnvironmentOutlined,
  ExpandOutlined,
  LeftOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  Tooltip,
} from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { AddressForm } from "../../components/AddressForm";
import { CustomButton } from "../../components/CustomButton";
import { EmailsForm } from "../../components/EmailsForm";
import { PhonesForm } from "../../components/PhonesForm";
import { ProductsForm } from "../../components/ProductsForm";
import { formatCnpj } from "../../utils/mask";
import { getValidationSchema } from "./validations/validationSchema";

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;

export default function SupplierCreatEdit() {
  const { t } = useTranslation();

  const validationSchema = getValidationSchema();
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState([]);
  const location = useLocation();
  const isEditRoute = location.pathname.includes("edit");
  const isView = location.pathname.includes("view");

  const [initialValues, setInitialValues] = useState({
    name: "",
    cnpj: "",
    segment: "",
    farmer_id: "",
    adresses: [
      {
        id: "",
        street: "",
        neighborhood: "",
        city: "",
        state: "",
        zipCode: "",
        number: "",
        complement: "",
        latitude: "",
        longitude: "",
        type: "Residencial",
        publicly_accessible: false,
      },
    ],
    products: [{}],
    phones: [
      {
        id: "",
        phone: "",
        type: "Residencial",
        publicly_accessible: false,
      },
    ],
    emails: [
      {
        id: "",
        email: "",
        type: "Pessoal",
        publicly_accessible: false,
      },
    ],
  });

  const getSegments = () => {
    return ["Venda de adubo", "Venda de Produtos Agricolas"];
  };

  useEffect(() => {
    async function updateInitialValues() {
      if (isEditRoute || isView) {
        const data = await fetchData(id);
        setInitialValues(data);
      }
    }

    updateInitialValues();
  }, [isEditRoute, isView, id]);

  async function fetchData(id) {
    try {
      return {
        name: "Fornecedor 1",
        cnpj: "64.176.991/0001-40",
        farmer_id: "",
        segment: "Venda de adubo",
        publicly_accessible: false,
        products: [{}],
        adresses: [
          {
            id: id,
            street: "Rua de Teste",
            neighborhood: "Bairro de Teste",
            city: "Juiz de Fora",
            state: "MG",
            zipCode: "36038315",
            number: 12,
            complement: "Nenum",
            latitude: -21.761191953274114,
            longitude: -43.34287324167793,
            type: "Residencial",
            publicly_accessible: false,
          },
        ],
        phones: [
          {
            id: "1",
            phone: "32988362631",
            type: "Residencial",
          },
        ],
        emails: [
          {
            id: "1",
            email: "teste@email.com",
            type: "Pessoal",
          },
        ],
      };
    } catch (error) {
      console.log("Erro ao buscar dados:", error);
    }
  }

  async function saveForm(values) {
    console.log("Dados do Payload", values);
    try {
    } catch (error) {
      console.log("AQUI", error);
    }
  }

  const onChange = (key) => {
    setActiveKey(key);
  };

  function openPanels() {
    setActiveKey([1, 2, 3]);
  }

  function togglePanels() {
    if (activeKey.length === 3) {
      setActiveKey([]);
    } else {
      setActiveKey([1, 2, 3]);
    }
  }

  const genExtra = (key) => {
    switch (key) {
      case 1:
        return (
          <EnvironmentOutlined
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        );
      case 2:
        return (
          <PhoneOutlined
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        );
      case 3:
        return (
          <MailOutlined
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={saveForm}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form onSubmitCapture={handleSubmit}>
              <div style={{ position: "relative" }}>
                <Link to="/suppliers">
                  <Button
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      zIndex: 1000,
                    }}
                    type="text"
                    icon={<LeftOutlined />}
                  >
                    {t("To_go_back")}
                  </Button>
                </Link>
                <Row style={{ marginBottom: "20px" }}>
                  <Col xs={24}>
                    <Card className="card-no-border">
                      <Row align="middle" wrap={false}>
                        <Col xs={24} sm={16} md={15} lg={4}>
                          <img
                            src="https://via.placeholder.com/150"
                            alt="Imagem"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
              <Row
                gutter={16}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Col xs={24} md={8} style={{ marginBottom: "5px" }}>
                  <Form.Item
                    help={touched.name && errors.name ? errors.name : ""}
                    validateStatus={touched.name && errors.name ? "error" : ""}
                  >
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("Name")}
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} style={{ marginBottom: "5px" }}>
                  <Form.Item
                    help={touched.cnpj && errors.cnpj ? errors.cnpj : ""}
                    validateStatus={touched.cnpj && errors.cnpj ? "error" : ""}
                  >
                    <Input
                      name="cnpj"
                      value={values.cnpj}
                      onChange={(event) => {
                        const formattedValue = formatCnpj(event);
                        setFieldValue("cnpj", formattedValue);
                      }}
                      onBlur={handleBlur}
                      onKeyUp={(event) => formatCnpj(event)}
                      placeholder="CNPJ"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} style={{ marginBottom: "5px" }}>
                  <Form.Item
                    help={
                      touched.segment && errors.segment ? errors.segment : ""
                    }
                    validateStatus={
                      touched.segment && errors.segment ? "error" : ""
                    }
                  >
                    <Select
                      name="segment"
                      value={values.segment}
                      onChange={(value) =>
                        handleChange({
                          target: { name: "segment", value },
                        })
                      }
                      onBlur={handleBlur}
                      placeholder={t("Segment")}
                      disabled={isView}
                    >
                      {getSegments().map((segment) => (
                        <Option value={segment} key={segment}>
                          {segment}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: "10px" }}>
                <Col xs={24} md={4}>
                  <Form.Item
                    label={t("Publicly_accessible_label")}
                    help={
                      touched.publicly_accessible && errors.publicly_accessible
                        ? errors.publicly_accessible
                        : ""
                    }
                    validateStatus={
                      touched.publicly_accessible && errors.publicly_accessible
                        ? "error"
                        : ""
                    }
                  >
                    <Tooltip title={t("Publicly_accessible")}>
                      <Checkbox
                        name="publicly_accessible"
                        disabled={isView}
                        checked={values.publicly_accessible}
                        onChange={(e) =>
                          setFieldValue("publicly_accessible", e.target.checked)
                        }
                        onBlur={handleBlur}
                      />
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>

              <Tabs defaultActiveKey="1">
                <TabPane tab={t("Info_basics")} key="1">
                  <Row justify="end" style={{ marginBottom: "15px" }}>
                    <Col>
                      <Button
                        type="dashed"
                        onClick={togglePanels}
                        icon={
                          activeKey.length === 3 ? (
                            <CompressOutlined />
                          ) : (
                            <ExpandOutlined />
                          )
                        }
                      >
                        {activeKey.length === 3 ? t("Colapse") : t("Expand")}
                      </Button>
                    </Col>
                  </Row>
                  <Collapse activeKey={activeKey} onChange={onChange}>
                    <Panel header={t("Adresses")} key="1" extra={genExtra(1)}>
                      <AddressForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        onlyOne={true}
                        allowDelete={false}
                        allowLatLong={false}
                        setFieldValue={setFieldValue}
                        isView={isView}
                      />
                    </Panel>
                    <Panel header={t("Phone")} key="2" extra={genExtra(2)}>
                      <PhonesForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        isView={isView}
                      />
                    </Panel>
                    <Panel header={t("Emails")} key="3" extra={genExtra(3)}>
                      <EmailsForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        isView={isView}
                      />
                    </Panel>
                  </Collapse>
                </TabPane>
                <TabPane tab={t("Products")} key="2">
                  <ProductsForm
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    allowDelete={true}
                    isView={isView}
                  />
                </TabPane>
              </Tabs>

              <div style={{ marginTop: "32px" }}>
                {!isView && (
                  <CustomButton
                    isEditRoute={isEditRoute}
                    handleClick={openPanels}
                  ></CustomButton>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
