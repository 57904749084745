import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Row, Space, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { CustomButton } from "./CustomButton";

const { Search } = Input;

export const Grid = ({
  data,
  total,
  pageSize,
  currentPage,
  onAdd,
  onEdit,
  onDelete,
  onView,
  onChangePage,
  onChange,
  onSearch,
  columns,
  showViewButton = false,
  showEditButton = true,
  showDeleteButton = true,
  showCreateButton = true,
  nameButtom,
  placeholderSearch,
  size = "small",
  bordered = false,
  loading,
  showSizeChanger = false,
}) => {
  const { t } = useTranslation();
  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {showEditButton && (
            <Button
              onClick={() => onEdit(record.key)}
              icon={<EditOutlined />}
              type="primary"
            >
              {t("Edit")}
            </Button>
          )}
          {showDeleteButton && (
            <Button
              onClick={() => onDelete(record.key)}
              icon={<DeleteOutlined />}
              type="dashed"
              danger
            >
              {t("Delete")}
            </Button>
          )}
          {showViewButton && (
            <Button
              onClick={() => onView(record.key)}
              icon={<EyeOutlined />}
              type="default"
            >
              {t("View")}
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const combinedColumns = columns.map((column) => {
    if (column.key === "name" || column.key === "createdAt") {
      return {
        ...column,
        sorter: (a, b) => a[column.key].localeCompare(b[column.key]),
      };
    }
    return column;
  });

  combinedColumns.push(...actionsColumn);

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col>
          {showCreateButton && (
            <CustomButton
              handleClick={onAdd}
              isDefault={false}
              isEditRoute={false}
              text={nameButtom}
              icon={<PlusCircleOutlined />}
            ></CustomButton>
          )}
        </Col>
        <Col>
          <Search
            placeholder={placeholderSearch}
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      <Table
        columns={combinedColumns}
        dataSource={data}
        onChange={onChange}
        sticky
        bordered={bordered}
        loading={loading}
        size={size}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: onChangePage,
          pageSizeOptions: ["10", "20", "30"],
          showSizeChanger: showSizeChanger,
          defaultPageSize: 10,
        }}
      />
    </div>
  );
};
