import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { changePasswordService } from "./service/farmer.service";

export default function PerfilChangePassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
      .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
      .matches(/[0-9]/, "A senha deve conter pelo menos um número")
      .required("A senha é obrigatória"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "As senhas não coincidem")
      .required("Confirme sua senha"),
  });

  async function changePasswordPerfil(values) {
    try {
      await changePasswordService(values.password, values.confirm_password);
      navigate("/login");
    } catch (error) {
      console.log("AQUI", error);
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          password: "",
          confirm_password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={changePasswordPerfil}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <Form layout="vertical" onSubmitCapture={handleSubmit}>
            <Form.Item
              help={touched.password && errors.password ? errors.password : ""}
              validateStatus={
                touched.password && errors.password ? "error" : ""
              }
            >
              <Input.Password
                prefix={<LockOutlined />}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("Password")}
                style={{ width: "25%" }}
              />
            </Form.Item>
            <Form.Item
              help={
                touched.confirm_password && errors.confirm_password
                  ? errors.confirm_password
                  : ""
              }
              validateStatus={
                touched.confirm_password && errors.confirm_password
                  ? "error"
                  : ""
              }
            >
              <Input.Password
                prefix={<LockOutlined />}
                name="confirm_password"
                value={values.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("Confirm_Password")}
                style={{ width: "25%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{ width: "25%" }}
              >
                <UnlockOutlined /> {t("Change")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
}
